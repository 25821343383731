import { HttpService } from '@/services/HttpService';

class Pladis {
  subdomain = '/pladis';
  getHeader(partnerId) {
    return HttpService.get(`${this.subdomain}/header?partnerId=${partnerId}`);
  }
  getCategories(partnerId) {
    return HttpService.get(`${this.subdomain}/categories?partnerId=${partnerId}`);
  }
  getProductsByCategoryId(categoryId) {
    return HttpService.get(`${this.subdomain}/products/${categoryId}`);
  }
  getProductsAndPriceList(partnerId) {
    return HttpService.get(`${this.subdomain}/pricelist?partnerId=${partnerId}`);
  }
}

export default new Pladis();
