import RouteBase from '@/router/routes/RouteBase.js';

class RoutesAthentication {
  subFolderPath = '/authentication';
  isPublic = true;

  Login = new RouteBase({
    name: 'login',
    path: 'login',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Login',
    isPublic: this.isPublic,
  });

  ForgotPassword = new RouteBase({
    name: 'forgot-password',
    path: 'forgot-password',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ForgotPassword',
    isPublic: this.isPublic,
  });

  PmtepLogin = new RouteBase({
    name: 'pmtep-login',
    path: 'pmtep-login/:token?',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PmtepLogin',
    isPublic: this.isPublic,
  });
}

export default new RoutesAthentication();
