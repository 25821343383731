import { HttpService } from '@/services/HttpService';

class EventLog {
  subdomain = '/eventlog';
  /**
   * Post event log
   * @returns {Promise}
   */
  postEventLog(params, url) {
    return HttpService.post(`${this.subdomain}/pagevisit`, { pageName: params, pageUrl: url });
  }
}

export default new EventLog();
