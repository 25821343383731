import { HttpService } from '@/services/HttpService';

class Agreement {
  subdomain = '/finance/agreements';
  /**
   * GET /finance/agreements/list
   * @returns {Promise}
   */
  getAgreements() {
    return HttpService.get(`${this.subdomain}/list`);
  }
  /**
   * GET /finance/agreements/detail?CustomerAgreementId=9c0fb4cf-5afd-4d38-bf63-94bda4c1244d
   * @returns {Promise}
   */
  getAgreementDetail(id) {
    return HttpService.get(`${this.subdomain}/detail?CustomerAgreementId=${id}`);
  }
}

export default new Agreement();
