<template>
  <div class="app-wrapper">
    <VueOverlay v-if="getOverlayVisibilityStatus" />
    <router-view />
    <BrandErrorModal />
  </div>
</template>

<script>
import BrandErrorModal from '@/components/brand/Modals/BrandModal/BrandErrorModal.vue';
import { mapGetters } from 'vuex';
import VueOverlay from '@/components/shared/VueOverlay/VueOverlay.vue';
export default {
  name: `App`,
  components: {
    VueOverlay,
    BrandErrorModal,
  },
  data() {
    return {};
  },
  beforeMount() {
    this.cookielawScripts();
  },
  computed: {
    ...mapGetters('app', ['getOverlayVisibilityStatus']),
  },
  watch: {
    //** Watch overlay state for toggle body scroll */
    getOverlayVisibilityStatus: () => {
      this && this.getOverlayVisibilityStatus
        ? (document.documentElement.style.overflow = 'hidden')
        : (document.documentElement.style.overflow = 'initial');

      return false;
    },
  },
  created() {
    document.title = process.env.VUE_APP_PROJECT_NAME;
  },
  methods: {
    async loadScript(src, attributes = {}) {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;

        for (const attr in attributes) {
          script.setAttribute(attr, attributes[attr]);
        }

        script.onload = resolve;
        script.onerror = reject;

        document.head.appendChild(script);
      });
    },
    createOptanonWrapperFunction() {
      const script = document.createElement('script');
      script.textContent = 'function OptanonWrapper() {}';
      document.head.appendChild(script);
    },
    cookielawScripts() {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      const isDevOrQa = !!(apiBaseUrl.includes('qa') || apiBaseUrl.includes('dev'));
      const dataDomainScript = `1255f79f-3c27-4484-adb2-318896c12a33${isDevOrQa ? '-test' : ''}`;
      const scriptUrl = `https://cdn.cookielaw.org/consent/1255f79f-3c27-4484-adb2-318896c12a33${
        isDevOrQa ? '-test' : ''
      }/OtAutoBlock.js`;

      this.loadScript(scriptUrl);

      this.loadScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', {
        'data-document-language': 'true',
        'data-domain-script': dataDomainScript,
        charset: 'UTF-8',
      });

      this.createOptanonWrapperFunction();
    },
  },
};
</script>

<style lang="scss">
@import '~@/styles/reset';

// .app-wrapper {
//   height: 100vh;
// }
</style>
