import RouteBase from '@/router/routes/RouteBase.js';

class RoutesPlayAndWin {
  subFolderPath = '/playAndWin';
  isPublic = false;

  PlayAndWinHome = new RouteBase({
    name: 'play-and-win-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PlayAndWinHome',
    isPublic: this.isPublic,
    meta: {
      icon: 'cup',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Oyna Kazan',
        },
      ],
    },
  });

  PlayAndWinDetail = new RouteBase({
    name: 'play-and-win-detail',
    path: 'detail',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PlayAndWinDetail',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Oyna Kazan',
          link: '/secure/play-and-win',
        },
        {
          name: '',
        },
      ],
    },
  });
}

export default new RoutesPlayAndWin();
