import Vue from 'vue';
import VueRouter from 'vue-router';
import { store } from '@/store/store';
import routes from '@/router/routes';
import StorageHelper from '@/utils/storageHelper';
import GtmHelper from '@/utils/gtmHelper';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import { Finance, User, EventLog } from '@/services/Api/index';
import { loadScript } from '@/utils/scriptUtils';
import { isPwaModeEnabled, isMobileDevice, deviceType } from '@/utils/helpers';
import { getScreenCodes } from '@/utils/brandUtils.js';
import UserAgentHelper from '@/utils/agentUtils';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

let insiderApiLoadCount = 0;

const setInsiderObject = userDetails => {
  if (insiderApiLoadCount != 0) return;

  if (userDetails) {
    if (userDetails.first_name !== null) {
      userDetails.first_name = window.btoa(window.encodeURI(userDetails.first_name));
    }
    if (userDetails.last_name !== null) {
      userDetails.last_name = window.btoa(window.encodeURI(userDetails.last_name));
    }
    if (userDetails.customer_name !== null) {
      userDetails.customer_name = window.btoa(window.encodeURI(userDetails.customer_name));
    }
    if (userDetails.userInsider !== null && userDetails.userInsider.customerGeo !== null) {
      if (userDetails.userInsider.customerGeo.teUser !== null) {
        userDetails.userInsider.customerGeo.teUser = window.btoa(
          window.encodeURI(userDetails.userInsider.customerGeo.teUser),
        );
      }
      if (userDetails.userInsider.customerGeo.teUserPhone !== null) {
        userDetails.userInsider.customerGeo.teUserPhone = window.btoa(
          window.encodeURI(userDetails.userInsider.customerGeo.teUserPhone),
        );
      }
    }

    const { insiderObject, ...rest } = userDetails;
    window.insider_object = {
      user: {
        uuid: insiderObject.uuid,
        gdpr_optin: insiderObject.gdpr_optin,
        //username: insiderObject.username,
        email_optin: insiderObject.email_optin,
        //phone_number: insiderObject.phone_number,
        sms_optin: insiderObject.sms_optin,
        PointLoss: insiderObject.PointLoss,
        posId: insiderObject.posId,
        businessPartners: insiderObject.businessPartners.map(i => i.code).toString(),
        custom: { ...rest },
      },
    };
  }

  setUseInsiderApi();
};

const setUseInsiderApi = () => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (!isAuthenticated) return;
  let scriptUrl = '//pmaktif.api.useinsider.com/ins.js?id=10001898';
  loadScript(scriptUrl, null, null, true, false);
  ++insiderApiLoadCount;
};

const initTracking = (userDetails, cookieSettings, from, to) => {
  if (window.insiderObject) {
    if (window.insider_object?.user?.custom) {
      window.insider_object.user.custom = userDetails;
      window.insider_object.user.custom.categoryStatuses =
        store.getters['app/getOtpCategoryStatuses'];
    }
  }
  let cookies = checkCookieEnabled(cookieSettings);

  if (cookies.performance) {
    //performans cookie açık ise gtm ve datalayerı init ediyoruz
    let gtmHelper = new GtmHelper(userDetails);
    gtmHelper.initDataLayer(from, to);
  }

  if (cookies.tracking) {
    //tracking cookieler açık ise insider tracking script yükleniyor
    let scriptUrl = `//pixel.mathtag.com/event/jsmt_id=1365007&mt_adid=213685&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=${userDetails.id}&s2=&s3=`;

    if (!window.trackingScriptLoaded) {
      window.trackingScriptLoaded = true;
      loadScript(scriptUrl, null, 'JavaScript1.1');
    }
  }
};

const setAuthDataFromStorage = async () => {
  const token = new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).get();
  const refreshToken = new StorageHelper({
    id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY,
  }).get();
  const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();
  const pos = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).get();
  await store.dispatch('auth/setAuthData', {
    token,
    refreshToken: { token: refreshToken },
    user,
    pos,
  });
};

const setDefaultStatus = async () => {
  await store.dispatch('app/setMenuStatus', false);
  await store.dispatch('app/setOverlayFunction', true);
  await store.dispatch('app/setAppbarConfig', {});
  await store.dispatch('app/setModalConfig', {});
  await store.dispatch('app/setErrorModalConfig', {});
};

/*set insider and iProspect models */
const setUserDetails = async (from, to) => {
  let userDetails = new StorageHelper({
    id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
  }).get(); //await store.getters['auth/getUserDetails'];
  let cookieSettings = new StorageHelper({
    id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS,
  }).get(); //await store.getters['app/getCookieSettings'];

  if (!userDetails || !cookieSettings) {
    //set userDetails and cookieSettings store data and insider model
    let res = await User.getUserDetail();
    if (res && res.data.Data && res.data.Data.userDetail) {
      userDetails = res.data.Data.userDetail;
      cookieSettings = res.data.Data.cookieSettings;
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL }).set(userDetails);
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).set(
        cookieSettings,
      );
      await store.dispatch('auth/setUserDetails', userDetails);
      await store.dispatch('app/setCookieSettings', cookieSettings);
      if (to.path !== 'fun') {
        initTracking(userDetails, cookieSettings, from, to);
      }
      setInsiderObject(userDetails);
    }
  } else {
    await store.dispatch('auth/setUserDetails', userDetails);
    await store.dispatch('app/setCookieSettings', cookieSettings);
    if (to.path !== 'fun') {
      initTracking(userDetails, cookieSettings, from, to);
    }
    setInsiderObject(userDetails);
  }
};

const setCustomerDetails = () => {
  Finance.getCustomerDetail().then(res => {
    const {
      Data: { customer },
    } = res.data;
    //posDetail ve pos points store ve localstorage'e ekleniyor
    if (customer && customer.length > 0)
      store.dispatch('app/setCustomerDetail', customer[0]).then(() => {
        if (customer[0].partners) {
          let point = customer[0].partners.reduce((a, b) => {
            return a + b['point'];
          }, 0);
          store.dispatch('app/setCustomerPoints', point);
          new StorageHelper({
            id: process.env.VUE_APP_STORAGE_CURRENT_POS_POINTS,
          }).set(point);
        }
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_CURRENT_POS_DETAIL,
        }).set(customer[0]);
      });
  });
};

const checkCookieEnabled = cookieSettings => {
  let cookies = { tracking: false, performance: false };

  if (cookieSettings && cookieSettings.cookieTypes) {
    cookieSettings.cookieTypes.forEach(item => {
      if (item.enable) {
        if (item.type === 2) {
          cookies.tracking = true;
        } else if (item.type === 3) {
          cookies.performance = true;
        }
      }
    });
  }
  return cookies;
};
/*
 * Router Guard
 * ********************************
 * Deny unauthenticated users for secure route accessibility
 * Deny or redirect authenticated users for authentication routes
 */
router.beforeEach((to, from, next) => {
  setAuthDataFromStorage();
  setDefaultStatus();
  store.dispatch('app/setAddedToHomeScreenStatus', isMobileDevice() && isPwaModeEnabled());
  store.dispatch('app/setDeviceType', deviceType());
  store.dispatch('app/setSearchedText', '');
  // Set manage-my-business active tab only if user refresh the page
  if (
    from.name === RoutesSecure.ManageMyBusiness.name &&
    to.name !== RoutesSecure.ManageMyBusiness.name
  ) {
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_MANAGE_BUSINESS_ACTIVE_TAB }).set(null);
  }

  const isAuthenticated = store.getters['auth/isAuthenticated'];

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      const token = store.getters['auth/getToken']; //  const token = new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).get();
      if (token) {
        if (to.name === RoutesLogin.SelectPos.name) {
          next();
        }
      } else {
        next({ path: `${RoutesRoot.Home.path}`, query: { redirect: to.fullPath } });
      }
    } else {
      let screenCodes =
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_SCREEN_CODES,
        }).get() || [];
      if (screenCodes.length === 0) {
        //get screen codes data from menuItems storage
        let menuData = new StorageHelper({
          id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
        }).get();
        if (menuData && menuData.menuItems) {
          screenCodes = getScreenCodes(menuData.menuItems);
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_SCREEN_CODES }).set(screenCodes);
        }
      }
      const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();

      const posDetail = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CURRENT_POS_DETAIL,
      }).get();

      const isPartner = posDetail?.partners?.some(item => item.code === 'PMI');
      if (!screenCodes || screenCodes.length === 0) {
        next();
      } else if (screenCodes.includes(to.meta.screenCode) || to.meta.screenCode === -1) {
        // Allow user who has the permission
        if (
          user.isImageRecognitionUser &&
          to.meta.screenCode === -1 &&
          to.name !== 'profile' &&
          to.name !== 'data-policy' &&
          to.name !== 'legal-warning' &&
          to.name !== 'cookie-policy'
        ) {
          next({
            path: `${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}`,
          });
        } else if (
          !isPartner &&
          ['Endorsement', 'Turnover', 'EndorsementByCategory', 'TotalLoss'].includes(to.name)
        ) {
          next({ path: `${RoutesRoot.NotFound.path}`, query: { type: 1 } });
        } else {
          let clientInfo = new UserAgentHelper();
          if (to.name === 'QRReader' && !clientInfo.isChrome) {
            next({ path: `${RoutesRoot.Secure.path}/${RoutesSecure.ImageRecognition.path}` });
          }
          next();
        }
      } else {
        next({ path: `${RoutesRoot.NotFound.path}`, query: { type: 1 } });
      }
    }
  } else {
    if (isAuthenticated && to.matched.some(record => record.meta.isAuthDenied)) {
      if (from.meta && !from.meta.isAuthDenied) {
        if (
          to.name === RoutesAthentication.PmtepLogin.name &&
          from.name !== RoutesAthentication.PmtepLogin.name
        ) {
          next();
        } else {
          return next({ path: `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}` });
        }
      } else {
        return next({ path: from.fullPath });
      }
    }
    next();
  }
});

router.afterEach((to, from) => {
  const isAuthenticated = store.getters['auth/isAuthenticated'];
  if (isAuthenticated) {
    setUserDetails(from, to);
    //reinit insider integration
    if (window.spApi) {
      window.spApi.reInitOnChange();
    }

    if (to.meta && to.meta.startupConfig) {
      if (to.meta.startupConfig.setCustomerDetail) {
        setCustomerDetails();
      }
    }

    if (to.path && to.name !== 'feed-detail') {
      EventLog.postEventLog(to.path, window.location.href);
    }
  }
});
