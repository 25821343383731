export default {
  code: 'tr',
  messages: {
    body: {
      likeCount: `{count} kişi beğendi`,
      headers: {
        nextOrder: 'SONRAKİ ÖN SİPARİŞ',
        wallet: 'CÜZDAN',
      },
    },
    buttons: {
      doAndWin: 'YAP KAZAN',
      scratchAndWin: 'KAZI KAZAN',
    },
  },
};
