export const AuthGetters = {
  isAuthenticated(state) {
    return state.accessToken !== null && state.user !== null && state.selectedPos !== null;
  },
  getUserModel(state) {
    return state.user;
  },
  getToken(state) {
    return state.accessToken;
  },
  getRefreshToken(state) {
    return state.refreshToken;
  },
  getPos(state) {
    return state.selectedPos;
  },
  getPosList(state) {
    return state.posList;
  },
  getUserDetails(state) {
    return state.userDetails;
  },
  getAccountType(state) {
    return state.accountType;
  },
  getAfterLogin(state) {
    return state.afterLogin;
  },
  isEOrderUser(state) {
    return state.userDetails.isEOrderUser;
  },
};
