const resolve = (path, obj) => {
  return path.split('.').reduce((prev, curr) => {
    return prev ? prev[curr] : null;
  }, obj || self);
};

const removeTurkishChars = text => {
  let returnText = text
    .replace(/ı/g, 'i')
    .replace(/ö/g, 'o')
    .replace(/ü/g, 'u')
    .replace(/ç/g, 'c')
    .replace(/ş/g, 's')
    .replace(/I/g, 'i')
    .replace(/İ/g, 'I')
    .replace(/Ö/g, 'O')
    .replace(/Ü/g, 'U')
    .replace(/Ç/g, 'C')
    .replace(/Ş/g, 'S')
    .toLowerCase();
  return returnText;
};

//check if string (str) contains any of strings in the givven array (arr)
const searchIn = (arr, str) => {
  let regexFromMyArray = new RegExp(arr.join('|'), 'gi');
  return regexFromMyArray.test(str);
};
// searches array or string for a value
const SearchObject = (container, value) => {
  let returnValue = false;
  let pos = container.indexOf(value);
  if (pos >= 0) {
    returnValue = true;
  }
  return returnValue;
};

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    let resolvedKey = resolve(key, x);
    (rv[resolvedKey] = rv[resolvedKey] || []).push(x);
    return rv;
  }, {});
};

const sumBy = (arr, key, filter) => {
  return arr.reduce((a, b) => {
    let resolvedKey = resolve(key, b);
    if (filter && filter.key && filter.value) {
      if (b[filter.key] === filter.value) return a + (resolvedKey || 0);
      else return a;
    } else {
      return a + (resolvedKey || 0);
    }
  }, 0);
};

// finds an item in the array with given value, and removes it from array
const removeItemByValue = (arr, value) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    }
  }
};
// finds an item in the array with given value, and removes it from array
const removeItemByKeyValue = (arr, key, value) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][key] === value) {
      arr.splice(i, 1);
    }
  }
};

const sortBy = (arr, sortkey, order) => {
  order = order == 'asc' ? 1 : -1;
  if (sortkey && arr && Array.isArray(arr)) {
    return arr.slice().sort((a, b) => {
      a = resolve(sortkey, a);
      b = resolve(sortkey, b);
      //Alan değerlerinin normalizasyonu
      a = a ? a : '';
      b = b ? b : '';

      if (typeof a === 'string' || typeof b === 'string') {
        a = removeTurkishChars(a);
        b = removeTurkishChars(b);
      }

      return (a === b ? 0 : a > b ? 1 : -1) * order;
    });
  } else return arr;
};

const filterBy = (arr, filterKey, filterStr) => {
  if (filterStr && arr && filterKey && Array.isArray(arr)) {
    return arr.filter(i => {
      // örn. key=PersonInfo.PersonName, i = Persons[0] (value = Persons[0].PersonInfo.PersonName)
      // resolve etmeden PersonInfo.PersonName propertisine erişemessin
      let resolvedKeyVal = resolve(filterKey, i);
      if (typeof resolvedKeyVal === 'string' && typeof filterStr === 'string') {
        let lowName =
          typeof resolvedKeyVal === 'string' ? removeTurkishChars(resolvedKeyVal) : resolvedKeyVal;
        let lowSearch = typeof filterStr === 'string' ? removeTurkishChars(filterStr) : filterStr;

        return SearchObject(lowName, lowSearch);
      } else return resolvedKeyVal === filterStr;
    });
  } else return arr;
};

const convertToObjectBy = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

const maxBy = (arr, key) => {
  if (Array.isArray(arr) && key) {
    let objWithMaxVal = arr.reduce((p, v) => {
      return p[key] > v[key] ? p : v;
    });
    return objWithMaxVal[key];
  } else {
    return arr.reduce((p, v) => {
      return p > v ? p : v;
    });
  }
};

const filterByMultiple = (arr, filterKey1, filterKey2, filterStr) => {
  if (filterStr && arr && filterKey1 && filterKey2 && Array.isArray(arr)) {
    return arr.filter(i => {
      // örn. key=PersonInfo.PersonName, i = Persons[0] (value = Persons[0].PersonInfo.PersonName)
      // resolve etmeden PersonInfo.PersonName propertisine erişemessin
      let resolvedKeyVal1 = resolve(filterKey1, i);
      let resolvedKeyVal2 = resolve(filterKey2, i);

      if (
        typeof resolvedKeyVal1 === 'string' &&
        typeof resolvedKeyVal2 === 'string' &&
        typeof filterStr === 'string'
      ) {
        let lowName1 =
          typeof resolvedKeyVal1 === 'string'
            ? removeTurkishChars(resolvedKeyVal1)
            : resolvedKeyVal1;
        let lowName2 =
          typeof resolvedKeyVal2 === 'string'
            ? removeTurkishChars(resolvedKeyVal2)
            : resolvedKeyVal2;

        let lowSearch = typeof filterStr === 'string' ? removeTurkishChars(filterStr) : filterStr;

        return SearchObject(lowName1, lowSearch) || SearchObject(lowName2, lowSearch);
      } else return resolvedKeyVal1 === filterStr || resolvedKeyVal2 === filterStr;
    });
  } else return arr;
};

export {
  groupBy,
  removeItemByValue,
  removeItemByKeyValue,
  sortBy,
  filterBy,
  filterByMultiple,
  sumBy,
  maxBy,
  convertToObjectBy,
  searchIn,
  removeTurkishChars,
};
