export const AppGetters = {
  cookieConsentVersion(state) {
    return state.cookieConsentVersion;
  },
  getOverlayVisibilityStatus(state) {
    return state.isOverlayVisible;
  },
  getOverlayFunctionStatus(state) {
    return state.isOverlayEnabled;
  },
  getAppbarConfig(state) {
    return state.appbarConfig;
  },
  getMenuStatus(state) {
    return state.isMenuOpened;
  },
  getBottomBarStatus(state) {
    return state.showBottomBar;
  },
  getModalConfig(state) {
    return state.modalConfig;
  },
  getMenuItems(state) {
    return state.menuItems;
  },
  getCurrentMenuItems(state) {
    return state.currentMenuItems;
  },
  getScrollStatus(state) {
    return state.overflow;
  },
  getFunPageModalStatus(state) {
    return state.showModalOnFunPage;
  },
  getSearchedText(state) {
    return state.searchedText;
  },
  getCustomerPoints(state) {
    return state.customerPoints;
  },
  getCustomerDetail(state) {
    return state.customerDetail;
  },
  getCookieSettings(state) {
    return state.cookieSettings;
  },
  getVirtualVisit(state) {
    return state.virtualVisit.url;
  },
  getAddedToHomeScreenStatus(state) {
    return state.addedToHomeScreenStatus;
  },
  getDeviceType(state) {
    return state.deviceType;
  },
  getErrorModalConfig(state) {
    return state.errorModalConfig;
  },
  getDoWinCardRefresh(state) {
    return state.doWinCardRefresh;
  },
  getMarathonCardRefresh(state) {
    return state.marathonCardRefresh;
  },
  getStatusModalConfig(state) {
    return state.statusModalConfig;
  },
  getRacks(state) {
    return state.racks;
  },
  getQrResponse(state) {
    return state.qrResponse;
  },
  getLocation(state) {
    return state.location;
  },
  getCloseClick(state) {
    return state.onCloseClick;
  },
  getOtpCategoryStatuses(state) {
    return state.otpCategoryStatuses;
  },
  getLastPosition(state) {
    return state.lastPosition;
  },
  getContentTypes(state) {
    return state.contentTypes;
  },
  getCategories(state) {
    return state.categories;
  },
  getActiveDowinCount(state) {
    return state.activeDowinCount;
  },
  getQrViewStatus(state) {
    return state.qrViewStatus;
  },
  getNpsFinished(state) {
    return state.isNPSSurveyFinished;
  },
  getChangedPosToken(state) {
    return state.changedPosToken;
  },
  getRosettes(state) {
    return state.rosettes;
  },
  getPopups(state) {
    return state.popups;
  },
  getFeedTaskDetail(state) {
    return state.feedTaskDetail;
  },
  getRosetteRewardStatus(state) {
    return state.isRosetteRewardStatu;
  },
  getCurrentMarathon(state) {
    return state.currentMarathon;
  },
};
