import * as rules from 'vee-validate/dist/rules';
import { extend, configure } from 'vee-validate';
import { i18n } from '@/utils/initInternationalization.js';

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  },
});
const multiples = (value, multiplier) => {
  return Number(value) % Number(multiplier) === 0;
};

extend('multiples', multiples);

extend('decimal', value => {
  let regex = /^-{0,1}\d*,{0,1}\d+$/;
  return regex.test(value);
});

extend('atLeastTwoChar', value => {
  let list = 0;
  if (value.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g)) {
    list++;
  }
  if (value.match(/[0-9]/g)) {
    list++;
  }
  if (value.match(/[a-z]/g)) {
    list++;
  }
  if (value.match(/[A-Z]/g)) {
    list++;
  }
  if (list > 1) {
    return true;
  }
  return `Büyük harf, küçük harf, rakam, özel karakterden en az ikisini içermelidir.`;
});

// loop over all rules
for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
  });
}
