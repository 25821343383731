import { HttpService } from '@/services/HttpService';

class Fun {
  subdomain = '/content';
  /**
   * Get content categories
   * @returns {Promise}
   */
  getContentSubCategories(parentId) {
    return HttpService.get(`${this.subdomain}/type/${parentId}`);
  }
  /**
   * Get content categories
   * @returns {Promise}
   */
  getContentParentCategories() {
    return HttpService.get(`${this.subdomain}/type`);
  }
  /**
   * Get content by ID
   * @returns {Promise}
   */
  getContent(lastId, lastIndex, pageSize, contentType, searchedText) {
    if (searchedText) {
      let queryString = `?LastId=${lastId}&StartIndex=${lastIndex}&PageSize=${pageSize}&ContentTypeId=${contentType}&SearchText=${searchedText}`;
      return HttpService.get(`${this.subdomain}${queryString}`);
    } else {
      let queryString = `?LastId=${lastId}&StartIndex=${lastIndex}&PageSize=${pageSize}&ContentTypeId=${contentType}`;
      return HttpService.get(`${this.subdomain}${queryString}`);
    }
  }
}

export default new Fun();
