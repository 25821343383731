import RouteBase from '@/router/routes/RouteBase.js';

class RoutesRoot {
  subFolderPath = '';
  isPublic = true;

  Home = new RouteBase({
    name: 'home',
    path: '/',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Home',
    isPublic: this.isPublic,
  });
  Authentication = new RouteBase({
    name: 'auth',
    path: '/auth',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Authentication',
    isPublic: this.isPublic,
    meta: {
      isAuthDenied: true,
    },
  });

  Secure = new RouteBase({
    name: 'secure',
    path: '/secure',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'Secure',
    isPublic: false,
    meta: {
      requiresAuth: true,
    },
  });

  StyleGuide = new RouteBase({
    name: 'styleguide',
    path: '/styleguide',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'Styleguide',
    isPublic: this.isPublic,
  });

  NotFound = new RouteBase({
    name: 'not-found',
    path: '*',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'NotFound',
    isPublic: this.isPublic,
  });

  Ayd = new RouteBase({
    name: 'ayd',
    path: '/public/ayd',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'Ayd',
    isPublic: this.isPublic,
  });

  OneTrustCookieSettings = new RouteBase({
    path: '/one-trust-cookie-settings',
    name: 'OneTrustCookieSettings',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'OneTrustCookieSettings',
    isPublic: true,
  });
}

export default new RoutesRoot();
