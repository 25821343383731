import RouteBase from '@/router/routes/RouteBase.js';

class RoutesEntrepreneurship {
  subFolderPath = '/entrepreneurship';
  isPublic = false;
  title = 'Girişimcilik';

  Entrepreneurship = new RouteBase({
    name: 'entrepreneurship',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Entrepreneurship',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Girişimcilik',
        },
      ],
    },
  });
}

export default new RoutesEntrepreneurship();
