import RouteBase from '@/router/routes/RouteBase.js';

class RoutesStatic {
  defaultOptions = {
    componentFolderPath: '/static',
    isPublic: false,
    meta: { appbarConfig: { isCollapsable: false, hasBackButton: true } },
  };

  RegulationDetails = new RouteBase({
    name: 'regulation-details',
    path: 'regulation-details',
    componentFileName: 'RegulationDetails',
    ...this.defaultOptions,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Tek Tip Paket',
          link: '/secure/business-partners/pmi/plain-package',
        },
        {
          name: 'Regülasyon Detayları',
        },
      ],
    },
  });
  HealthWarnings = new RouteBase({
    name: 'health-warnings',
    path: 'health-warnings',
    componentFileName: 'HealthWarnings',
    ...this.defaultOptions,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Tek Tip Paket',
          link: '/secure/business-partners/pmi/plain-package',
        },
        {
          name: 'Sağlık Uyarıları',
        },
      ],
    },
  });
  FrequentlyAskedQuestions = new RouteBase({
    name: 'faq',
    path: 'faq',
    componentFileName: 'FrequentlyAskedQuestions',
    ...this.defaultOptions,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Tek Tip Paket',
          link: '/secure/business-partners/pmi/plain-package',
        },
        {
          name: 'Sıkça Sorulan Sorular',
        },
      ],
    },
  });
  CookiePolicy = new RouteBase({
    name: 'cookie-policy',
    path: 'cookie-policy',
    componentFileName: 'CookiePolicy',
    componentFolderPath: '/static',
    isPublic: false,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Çerez(Cookie) Politikası',
        },
      ],
    },
  });
}

export default new RoutesStatic();
