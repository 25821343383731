import { HttpService } from '@/services/HttpService';

class Trendyol {
  subdomain = '/trendyolwallet';
  getDisclaimerContent() {
    return HttpService.get(`${this.subdomain}/disclaimercontent`);
  }
  getPointConversionRate() {
    return HttpService.get(`${this.subdomain}/pointconversionrate`);
  }
  setConversion(pointToConvert) {
    return HttpService.post(`${this.subdomain}/confirmconversion`, pointToConvert);
  }
}
export default new Trendyol();
