import axios from 'axios';

import {
  setupRequestInterceptor,
  setupResponseInterceptor,
} from '@/services/HttpService/interceptors';
import { store } from '@/store/store';
import { router } from '@/router/router';

export let HttpService = axios.create();

export const initHttpService = (initStore, initRouter) => {
  HttpService = axios.create({
    baseURL:
      (initStore &&
        initStore.state &&
        initStore.state.app &&
        initStore.state.app.config &&
        initStore.state.app.config.api &&
        initStore.state.app.config.api.baseURL) ||
      '',
  });

  HttpService.store = initStore || store;
  HttpService.router = initRouter || router;
  HttpService.isReAuthenticating = false;
  HttpService.pendingRequests = [];
  HttpService.expireFailRequests = [];
  setupRequestInterceptor();
  setupResponseInterceptor(HttpService.router);
};

export const replaceOldToken = (request, accessToken) => {
  request.headers.Authorization = `Bearer ${accessToken}`;
  return request;
};
