import RouteBase from '@/router/routes/RouteBase.js';

class RoutesImageRecognition {
  subFolderPath = '/imageRecognition';
  isPublic = false;
  title = 'Image Recognition';

  ImageRecognition = new RouteBase({
    name: 'image-recognition-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ImageRecognition',
    isPublic: this.isPublic,
    screenCode: 19,
    meta: {
      icon: 'cameraBig',
      appbarConfig: {
        title: 'Merhaba ',
      },
    },
  });

  QRReader = new RouteBase({
    name: 'QRReader',
    path: 'qr-reader',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'QRReader',
    isPublic: this.isPublic,
    screenCode: 19,
    meta: {
      appbarConfig: {
        hasBackButton: true,
        isQrReaderPage: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Qr Okutma',
        },
      ],
    },
  });
  Main = new RouteBase({
    name: 'Main',
    path: 'main',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Main',
    isPublic: this.isPublic,
    screenCode: 19,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Qr Okutma',
        },
      ],
    },
  });

  Racks = new RouteBase({
    name: 'Racks',
    path: 'racks',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Racks',
    isPublic: this.isPublic,
    screenCode: 19,
    meta: {
      appbarConfig: {
        hasBackButton: true,
        isRackPage: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Raflar',
        },
      ],
    },
  });

  Faq = new RouteBase({
    name: 'Faq',
    path: 'faq',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Faq',
    isPublic: this.isPublic,
    screenCode: 20,
    meta: {
      icon: 'userGuide',
      appbarConfig: {},
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Sık Sorulan Sorular',
        },
      ],
    },
  });

  ReportProblem = new RouteBase({
    name: 'ReportProblem',
    path: 'report-problem',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ReportProblem',
    isPublic: this.isPublic,
    screenCode: 19,
    meta: {
      appbarConfig: {
        hasBackButton: true,
        isRackPage: true,
      },
    },
  });
}

export default new RoutesImageRecognition();
