import { HttpService } from '@/services/HttpService';

class Settings {
  subdomain = '/settings';
  /**
   * Change password
   *
   * @param {string} {Required} smsCode
   * @param {string} {Required} newPassword
   * @param {string} {Required} newPasswordRepeat
   * @returns {Promise}
   */
  changePassword(userCredentials) {
    return HttpService.put(`${this.subdomain}/password`, {
      ...userCredentials,
    });
  }
}

export default new Settings();
