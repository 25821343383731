export default {
  code: 'en',
  messages: {
    body: {
      likeCount: '{count} likes',
      headers: {
        nextOrder: 'en-SONRAKİ ÖN SİPARİŞ',
        wallet: 'en-CÜZDAN',
      },
    },
    buttons: {
      doAndWin: 'en-YAP KAZAN',
      scratchAndWin: 'en-KAZI KAZAN',
    },
  },
};
