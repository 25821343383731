import VueI18n from 'vue-i18n';
import Vue from 'vue';

import VALIDATION_MESSAGES_TR from '@/constants/i18n/tr/validation.js';
import VALIDATION_MESSAGES_EN from '@/constants/i18n/en/validation.js';
import HOME_PAGE_TR from '@/constants/i18n/tr/home.page.js';
import HOME_PAGE_EN from '@/constants/i18n/en/home.page.js';
import FEED_PAGE_TR from '@/constants/i18n/tr/feed.page.js';
import FEED_PAGE_EN from '@/constants/i18n/en/feed.page.js';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: process.env.VUE_APP_LOCALE_LANGUAGE,
  fallbackLocale: process.env.VUE_APP_FALLBACK_LANGUAGE,
  silentTranslationWarn: true,
  messages: {
    tr: {
      validation: { ...VALIDATION_MESSAGES_TR.messages },
      homePage: { ...HOME_PAGE_TR.messages },
      feedPage: { ...FEED_PAGE_TR.messages },
    },
    en: {
      validation: { ...VALIDATION_MESSAGES_EN.messages },
      homePage: { ...HOME_PAGE_EN.messages },
      feedPage: { ...FEED_PAGE_EN.messages },
    },
  },
});
