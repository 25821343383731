import RouteBase from '@/router/routes/RouteBase.js';

class RoutesPmi {
  subFolderPath = '/businessPartners/pmi';
  isPublic = false;

  Home = new RouteBase({
    name: 'business-partners-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Home',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        icon: 'wave',
        title: 'Philip Morris',
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
        },
      ],
    },
  });

  BrandDetail = new RouteBase({
    name: 'pmi-brand-detail',
    path: 'brand/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'BrandDetail',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Marka Detayı',
        },
      ],
    },
  });

  PlainPackage = new RouteBase({
    name: 'plain-package',
    path: 'plain-package',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PlainPackage',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        icon: 'briefcase',
        hasBackButton: true,
        title: 'Plain Package',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Tek Tip Paket',
        },
      ],
    },
  });

  PriceList = new RouteBase({
    name: 'price-list',
    path: 'price-list',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PriceList',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Fiyat Listesi',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Güncel Fiyat Listesi',
        },
      ],
    },
  });

  ProductList = new RouteBase({
    name: 'product-list',
    path: 'product-list',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ProductList',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Ürün Listesi',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Güncel Ürün Listesi',
        },
      ],
    },
  });

  Bills = new RouteBase({
    name: 'bills',
    path: 'bills',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Bills',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Faturalarım',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'Faturalarım',
        },
      ],
    },
  });
  Archive = new RouteBase({
    name: 'eArchive',
    path: 'e-archive',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Archive',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'E-Arşiv',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Philip Morris',
          link: '/secure/business-partners/pmi',
        },
        {
          name: 'E-Arşiv',
        },
      ],
    },
  });
}

export default new RoutesPmi();
