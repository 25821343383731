<template>
  <div class="full-page-overlay">
    <div class="loader-holder">
      <div class="loader">Loading...</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VueOverlay',
};
</script>

<style scoped lang="scss">
.full-page-overlay {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
  }
  .loader-holder {
    min-width: 160px;
    min-height: 160px;
    opacity: 0.7;
    border-radius: 20px;
    background-color: #000000;
    position: relative;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      left: 50%;
    }
    .loader {
      font-size: 10px;
      text-indent: -9999em;
      border-top: 8px solid palette-color-level('white', 100);
      border-right: 8px solid palette-color-level('white', 100);
      border-bottom: 8px solid palette-color-level('white', 100);
      border-left: 8px solid palette-color-level('red', 10);
      transform: translateX(-50%) translateY(-50%) translateZ(0);
      animation: load8 1.1s infinite linear;
    }

    @keyframes load8 {
      0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(0deg);
      }
      100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) rotate(360deg);
      }
    }
  }
}
</style>
