import RouteBase from '@/router/routes/RouteBase.js';

class RoutesMySurveys {
  subFolderPath = '/mySurveys';
  isPublic = false;

  MySurveys = new RouteBase({
    name: 'mysurveys',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Surveys',
    isPublic: this.isPublic,
    meta: {
      icon: 'iconAgreement',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Tüm Anketler',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Tüm Anketler',
          link: '/secure/my-surveys',
        },
      ],
    },
  });

  //   SurveysDetail = new RouteBase({
  //     name: 'surveys-detail',
  //     path: 'surveys/:id',
  //     componentFolderPath: `${this.subFolderPath}`,
  //     componentFileName: 'SurveysDetail',
  //     isPublic: this.isPublic,
  //     meta: {
  //       hideBottomBar: false,
  //       appbarConfig: {
  //         isCollapsable: false,
  //         hasBackButton: true,
  //         title: 'Tüm Anketler Detay',
  //       },
  //       breadcrumb: [
  //         {
  //           name: 'Ana Sayfa',
  //           link: '/secure/feed',
  //         },
  //         {
  //           name: 'Tüm Anketler',
  //           link: '/secure/agreements',
  //         },
  //         {
  //           name: 'Tüm Anketler Detay',
  //         },
  //       ],
  //     },
  //   });
}

export default new RoutesMySurveys();
