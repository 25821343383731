import { HttpService } from '@/services/HttpService';

class Gamification {
  subdomain = '/gamification';

  /*oyna kazan geçmiş skorları çeker*/
  getScores(gameType) {
    return HttpService.get(`${this.subdomain}/scoretracking?gameType=${gameType}`);
  }

  /*Oyna kazan testinin detaylarını çeker */
  getSurveyDetail(surveyID, taskUserID) {
    let url = `${this.subdomain}/survey?surveyId=${surveyID}`;
    if (taskUserID) {
      url += `&taskUserId=${taskUserID}`;
    }
    return HttpService.get(url);
  }
  /*günün oyna kazan yarışmasını(marathon) çeker */
  getDailyMarathon() {
    return HttpService.get(`${this.subdomain}/daily/marathon`);
  }
  /*günün oyna kazan yarışmasının(marathon) detaylarını çeker */
  getDailyMarathonDetail() {
    return HttpService.get(`${this.subdomain}/daily/marathon/detail`);
  }
  getMarathonSummary() {
    return HttpService.get(`${this.subdomain}/daily/surveyhistory`);
  }
  playWinSelectOption(params) {
    return HttpService.post(`${this.subdomain}/survey/option`, params);
  }
  /*aktif/pasif yapkazan listesini çeker */
  getDoWinTasks(isActive, filter) {
    let queryString = '';
    if (filter) queryString = `?${filter}`;

    return HttpService.get(
      `${this.subdomain}/${!isActive ? 'completedtasks' + queryString : 'incompletedtasks'}`,
    );
  }
  /* yapkazan görevini başlatma servisi*/
  sendTaskResponse(taskUserId) {
    return HttpService.post(`${this.subdomain}/task/response`, { taskUserId });
  }
  /*yap kazan listesinde filtre gruplarını çeker */
  getDoWinFilters() {
    return HttpService.get(`${this.subdomain}/task/filter`);
  }

  playWinFinishSurvey(surveyTakenId) {
    return HttpService.post(`${this.subdomain}/survey/finish`, { surveyTakenId });
  }
  getSurveys(type) {
    return HttpService.get(`${this.subdomain}/survey/list?type=${type}`);
  }
  saveSurvey(params) {
    return HttpService.post(`${this.subdomain}/survey/saveanswers`, params);
  }

  getFeedMarathon() {
    return HttpService.get(`${this.subdomain}/feed/marathon`);
  }
  getIframeGaming() {
    return HttpService.get(`${this.subdomain}/gamingproject`);
  }
  getNpsSurveyDetail(payload) {
    return HttpService.post(`/evalutionsurvey/detail`, payload);
  }
  setNpsAnswers(payload) {
    return HttpService.post(`/evalutionsurvey/answer`, payload);
  }
  setNpsPreviousAnswer(payload) {
    return HttpService.post(`/evalutionsurvey/previousAnswer`, payload);
  }
  getNpsDaily(payload) {
    return HttpService.post(`/evalutionsurvey/daily`, payload);
  }
  getTicketSurvey() {
    return HttpService.get(`/ticket/survey`);
  }
  getTicketSurveyDetail(id) {
    return HttpService.get(`/ticket/survey/detail/${id}`);
  }
  answerTicketSurvey(data) {
    return HttpService.post(`/ticket/survey/answer`, data);
  }
  getTaskPoint() {
    return HttpService.post(`${this.subdomain}/task/newly`, {});
  }
  getIncompletedSurveys() {
    return HttpService.get(`${this.subdomain}/incompletedsurveys`);
  }
  checkCustomerMarathon() {
    return HttpService.get(`${this.subdomain}/checkcustomermarathon`);
  }
  checkActiveMarathons() {
    return HttpService.get(`${this.subdomain}/checkactivemarathons`);
  }
  getYearlyRosetteInfo() {
    return HttpService.get(`${this.subdomain}/yearlyRosetteInfo`);
  }
  getWeeklyMarathonInfo() {
    return HttpService.get(`${this.subdomain}/weeklymarathoninfo`);
  }
}
export default new Gamification();
