import { register } from 'register-service-worker';
import Logger from '@/utils/logger';
if ('serviceWorker' in navigator) {
  const path = '/sw.js';
  register(path, {
    ready() {
      Logger.native(
        Logger.logLevels.INFO,
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      Logger.native(Logger.logLevels.INFO, 'Service worker has been registered.');
    },
    cached() {
      Logger.native(Logger.logLevels.WARNING, 'Content has been cached for offline use.');
    },
    updatefound() {
      Logger.native(Logger.logLevels.INFO, 'New content is downloading.');
    },
    updated() {
      Logger.native(Logger.logLevels.INFO, 'New content is available; please refresh.');
    },
    offline() {
      Logger.native(
        Logger.logLevels.WARNING,
        'No internet connection found. App is running in offline mode.',
      );
    },
    error(error) {
      Logger.native(Logger.logLevels.ERROR, 'Error during service worker registration:', error);
    },
  });

  let refreshing = false;

  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (refreshing) {
      return;
    }
    window.location.reload();
    refreshing = true;
  });
}
