import { AuthDefaultState } from '@/store/auth/state';
import { AuthActions } from '@/store/auth/actions';
import { AuthGetters } from '@/store/auth/getters';
import { AuthMutations } from '@/store/auth/mutations';

export const AuthModule = {
  namespaced: true,
  state: {
    ...AuthDefaultState(),
  },
  actions: {
    ...AuthActions,
  },
  getters: {
    ...AuthGetters,
  },
  mutations: {
    ...AuthMutations,
  },
};
