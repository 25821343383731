import { HttpService } from '@/services/HttpService';

class Notification {
  subdomain = '/notification';

  getNotificationCount() {
    return HttpService.get(`${this.subdomain}/activeNotificationCount`);
  }

  getUserNotification() {
    return HttpService.get(`${this.subdomain}/userNotification`);
  }

  getViewNotification(response) {
    return HttpService.post(`${this.subdomain}/viewNotification`, response);
  }
}

export default new Notification();
