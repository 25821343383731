import RouteBase from '@/router/routes/RouteBase.js';

class RoutesMyDocuments {
  subFolderPath = '/settings/myDocuments';
  isPublic = false;

  MyDocumentsNavigation = new RouteBase({
    name: 'myDocumentsNavigation',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'MyDocumentsNavigation',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
    },
  });
  Contracts = new RouteBase({
    name: 'contracts',
    path: 'contracts',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Contracts',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Belgeler',
        },
      ],
    },
  });
}

export default new RoutesMyDocuments();
