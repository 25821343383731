<template>
  <component :is="as" :class="[$style.text, cssClass]" ref="text" @click="click">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'VueText',
  props: {
    as: {
      type: String,
      default: 'p',
    },
    isUnderline: {
      type: Boolean,
      default: false,
    },
    weightLevel: {
      type: String,
    },
    sizeLevel: {
      type: String,
    },
    color: {
      type: String,
    },
    isSingleLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClass() {
      const classes = [
        this.$style[`text-font-size-${this.sizeLevel}`],
        this.$style[`text-font-weight-${this.weightLevel}`],
        this.$style[`text-${this.color}`],
      ];

      if (this.isSingleLine) {
        classes.push(this.$style['text-single-line']);
      }

      classes.push(this.isUnderline ? this.$style['text-underline'] : this.$style['text-basic']);

      return classes;
    },
  },
  methods: {
    click(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" module>
@import '~@/styles/styles';

.text {
  font-family: $font-family;
  font-size: revert;
  font-weight: revert;
  color: revert;
}

@each $variation, $size in $palette-font-sizes {
  .text-#{$variation} {
    font-size: $size;
  }
}

@each $variation, $weight in $palette-font-weights {
  .text-#{$variation} {
    font-weight: $weight;
  }
}

@each $variation, $color in $palette-colors {
  .text-#{$variation} {
    color: $color;
  }
}
.text-underline {
  text-decoration: underline;
}
.text-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-basic {
  text-decoration: none;
}
</style>
