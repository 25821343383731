import { HttpService } from '@/services/HttpService';

class StaticContent {
  subdomain = '/static_content';

  // Get static content with contentKey
  getStaticContent(contentType) {
    return HttpService.get(`${this.subdomain}?contentKey=${contentType}`);
  }
  // Get public static content with contentKey
  getPublicStaticContent(contentType) {
    return HttpService.get(`${this.subdomain}/public?contentKey=${contentType}`);
  }
  getChannelContent() {
    return HttpService.get(`${this.subdomain}/pos_channel_content`);
  }
}
export default new StaticContent();
