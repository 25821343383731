import { HttpService } from '@/services/HttpService';

class Product {
  subdomain = '/product';
  /**
   * Get all products
   * @returns {Promise}
   */
  getProducts() {
    // TODO: Servis tarafındaki tüm endpoint'ler tek tip yapıldığında bu kısım düzeltilecek.
    return HttpService.get(`${this.subdomain}/brand`);
  }
  /**
   * Get brand by brand id
   * @param {number} {Required} brandId
   * @returns {Promise}
   */
  getBrandById(brandId) {
    return HttpService.get(`${this.subdomain}/brand/${brandId}`);
  }
  /**
   * Get price list info
   * @returns {Promise}
   */
  getPriceList() {
    return HttpService.get(`${this.subdomain}/pricelist/pageinfo`);
  }
  /**
   * Get product list info
   * @returns {Promise}
   */
  getProductList() {
    return HttpService.get(`${this.subdomain}/productlist/pageinfo`);
  }
  /**
   * Get product sku list
   * @returns {Promise}
   */
  getSkuList() {
    return HttpService.get(`${this.subdomain}/skulist`);
  }
}

export default new Product();
