import { HttpService } from '@/services/HttpService';

class Learn {
  subdomain = '/learn';
  /**
   * Get annual profit
   * @returns {Promise}
   */
  getAnnualProfit(visitsPerWeek, invoiceAmount) {
    return HttpService.get(
      `${this.subdomain}/annualprofit?visitsPerWeek=${visitsPerWeek}&invoiceAmount=${invoiceAmount}`,
    );
  }

  /**
   * Get revenue distribution
   * @returns {Promise}
   */
  getRevenueDistribution() {
    return HttpService.get(`${this.subdomain}/revenuedist`);
  }

  /**
   * Get total loss
   * @returns {Promise}
   */
  getTotalLoss(items) {
    return HttpService.post(`${this.subdomain}/oosloss`, { items });
  }

  /**
   * Get Level Content Detail with Id
   * @returns {Promise}
   */
  getContentByLevelId(levelId) {
    return HttpService.get(`${this.subdomain}/content/${levelId}`);
  }

  /**
   * get learning categories
   * @returns {Promise}
   */
  getLearnContentTypes() {
    return HttpService.get(`${this.subdomain}/contenttypes`);
  }
}

export default new Learn();
