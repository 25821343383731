import { osTypes } from '@/constants/osTypes';
export const isPwaModeEnabled = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone ||
  document.referrer.includes('android-app://');

export const isMobileDevice = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
    navigator.userAgent,
  );

export const deviceType = () => {
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return osTypes.Ios;
  } else if (/android/i.test(navigator.userAgent)) {
    return osTypes.Android;
  } else {
    return osTypes.Web;
  }
};
