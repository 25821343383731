export default {
  code: 'en',
  messages: {
    logoText: 'en-KanalAktif',
    body: {
      title: 'en-Hoşgeldiniz',
      text: 'en-Giriş yapmak için aşağıdaki yöntemlerden birini seçin.',
    },
    buttons: {
      phoneText: 'en-Telefon Numaranız',
      customerNumberText: 'en-Müşteri Kodunuz',
      vknNumberText: 'en-VKN ile Giriş',
    },
  },
};
