import { store } from '@/store/store';
import StorageHelper from '@/utils/storageHelper';
const refreshApp = (appversion, webversion) => {
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_SCREEN_CODES }).remove();
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_MENU_ITEMS }).remove();
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_MENU_ITEMS }).remove();
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL }).remove();
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).remove();
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CHATBOT_MODEL }).remove();
  new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_VERSION }).set({
    appversion,
    webversion,
  });

  window.location.reload();
};
const clearAppData = (proc = 'logout', redirectUrl = '') => {
  store.dispatch('auth/setAuthData', null).then(() => {
    store.dispatch('app/setCookieSettings', null);
    store.dispatch('auth/setUserDetails', null);
    store.dispatch('app/setCustomerDetail', {});
    store.dispatch('app/setCustomerPoints', null);
    if (window.dataLayer) {
      window.dataLayer = [];
    }
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_ACCOUNT_TYPE }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_POINTS }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_DETAIL }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_CN_KEY }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_LOGIN_TYPE }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_SCREEN_CODES }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_MENU_ITEMS }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_MENU_ITEMS }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CHATBOT_MODEL }).remove();
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_TASK_COUNT }).remove();
    new StorageHelper({ id: 'PlayableInfo' }).remove();
    new StorageHelper({ id: 'ChatbotScript' }).remove();

    if (proc === 'logout') {
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_LOGOUT_FLAG }).set(true);
    } else if (proc === 'login') {
      new StorageHelper({ id: process.env.VUE_APP_STORAGE_USER_LOGOUT_FLAG }).remove();
    }
    if (redirectUrl) {
      window.location = redirectUrl;
    }
  });
};
export { clearAppData, refreshApp };
