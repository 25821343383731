import RouteBase from '@/router/routes/RouteBase.js';

class RoutesOtp {
  subFolderPath = '/otp';
  isPublic = false;
  title = 'Tütün Akademi';

  Otp = new RouteBase({
    name: 'Otp',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Otp',
    isPublic: this.isPublic,
    screenCode: 22,
    meta: {
      icon: 'otp',
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Tütün Akademi',
        },
      ],
    },
  });
  Learn = new RouteBase({
    name: 'Learn',
    path: ':name/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Learn',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Tütün Akademi',
          link: '/secure/tutun-akademi',
        },
        {
          name: 'Ürünün Uzmanı',
        },
      ],
    },
  });
  Faq = new RouteBase({
    name: 'Sss',
    path: 'sss',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Sss',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Tütün Akademi',
          link: '/secure/tutun-akademi',
        },
        {
          name: 'Sıkça Sorulan Sorular',
        },
      ],
    },
  });
}

export default new RoutesOtp();
