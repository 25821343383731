// RouteBase

class RouteBase {
  constructor(props) {
    const {
      name,
      path,
      componentFolderPath,
      componentFileName,
      isPublic,
      meta,
      screenCode = -1,
    } = props;
    this.name = name;
    this.path = path;
    this.isPublic = isPublic;
    this.componentFileName = componentFileName;
    this.componentFolderPath = componentFolderPath;
    this.meta = {
      ...meta,
      screenCode,
    };
  }

  generate(screenCode) {
    this.meta.screenCode = screenCode || this.meta.screenCode;
    return {
      name: this.name,
      path: this.path,
      meta: this.meta,
      component: () =>
        import(
          `@/views/pages/${this.isPublic ? 'public' : 'secure'}${this.componentFolderPath}/${
            this.componentFileName
          }.vue`
        ).then(m => m.default),
    };
  }
}

export default RouteBase;
