import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from '@/views/App.vue';
import { store } from '@/store/store';
import { router } from '@/router/router';

import { i18n } from '@/utils/initInternationalization.js';
import useLogger from '@/utils/logger/useLogger.js';
import '@/utils/validators/input-validation/initValidationValidator.js';

import { HttpService } from '@/services/HttpService';

import lazyLoadDirective from '@/directives/lazyLoadDirective';

export const createApp = () => {
  sync(store, router);

  HttpService.store = store;
  Vue.config.productionTip = false;

  useLogger(Vue);
  Vue.directive('lazyload', lazyLoadDirective);
  const app = new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
  });
  return { app, router, store };
};
