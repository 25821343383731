import RouteBase from '@/router/routes/RouteBase.js';

class RoutesSurvey {
  subFolderPath = '/surveys';
  isPublic = false;

  SurveyHome = new RouteBase({
    name: 'survey-home',
    path: 'survey-home',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'SurveyHome',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: false,
        title: 'Anket',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Anket',
        },
      ],
    },
  });

  TestHome = new RouteBase({
    name: 'test-home',
    path: 'test-home',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'TestHome',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: false,
        title: 'Test',
      },
    },
  });

  TakeSurvey = new RouteBase({
    name: 'take-survey',
    path: ':id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'TakeSurvey',
    isPublic: this.isPublic,
    meta: {
      hideBottomBar: true,
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: '',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Anket',
        },
      ],
    },
  });
}

export default new RoutesSurvey();
