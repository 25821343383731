import { AppDefaultState } from '@/store/app/state';
import { AppActions } from '@/store/app//actions';
import { AppGetters } from '@/store/app//getters';
import { AppMutations } from '@/store/app//mutations';

export const AppModule = {
  namespaced: true,
  state: {
    ...AppDefaultState(),
  },
  actions: {
    ...AppActions,
  },
  getters: {
    ...AppGetters,
  },
  mutations: {
    ...AppMutations,
  },
};
