import RouteBase from '@/router/routes/RouteBase.js';

class RoutesLogin {
  subFolderPath = '/authentication/login';
  isPublic = true;

  SelectLoginType = new RouteBase({
    name: 'login-type-selection',
    path: 'select',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'LoginTypeSelection',
    isPublic: this.isPublic,
  });

  WithPhoneNumber = new RouteBase({
    name: 'phone-number',
    path: 'phone-number',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'PhoneNumber',
    isPublic: this.isPublic,
  });

  WithCustomerNumber = new RouteBase({
    name: 'customer-number',
    path: 'customer-number',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'CustomerNumber',
    isPublic: this.isPublic,
  });

  WithTaxpayerIdNumber = new RouteBase({
    name: 'taxpayer-id-number',
    path: 'taxpayer-id-number',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'TaxpayerIdNumber',
    isPublic: this.isPublic,
  });

  FirstLogin = new RouteBase({
    name: 'first-login',
    path: 'first-login',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'FirstLogin',
    isPublic: this.isPublic,
  });

  SmsVerification = new RouteBase({
    name: 'sms-verification',
    path: 'sms-verification',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'SmsVerification',
    isPublic: this.isPublic,
  });

  NewPassword = new RouteBase({
    name: 'new-password',
    path: 'new-password',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'NewPassword',
    isPublic: this.isPublic,
  });

  SelectPos = new RouteBase({
    name: 'select-pos',
    path: 'select-pos',
    componentFolderPath: this.subFolderPath,
    componentFileName: 'SelectPos',
    isPublic: this.isPublic,
  });

  ExpiredPassword = new RouteBase({
    name: 'expired-password',
    path: 'expired-password',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ExpiredPassword',
    isPublic: this.isPublic,
  });
}

export default new RoutesLogin();
