export const APP_MUTATION_TYPES = {
  SET_COOKIE_CONSENT_VERSION: 'SET_COOKIE_CONSENT_VERSION',
  SET_OVERLAY_VISIBILITY: 'SET_OVERLAY_VISIBILITY',
  SET_OVERLAY_FUNCTION: 'SET_OVERLAY_FUNCTION',
  SET_APPBAR_CONFIG: 'SET_APPBAR_CONFIG',
  SET_MENU_STATUS: 'SET_MENU_STATUS',
  SET_BOTTOMBAR_STATUS: 'SET_BOTTOMBAR_STATUS',
  SET_MODAL_CONFIG: 'SET_MODAL_CONFIG',
  SET_ERROR_MODAL_CONFIG: 'SET_ERROR_MODAL_CONFIG',
  SET_STATUS_MODAL_CONFIG: 'SET_STATUS_MODAL_CONFIG',
  SET_MENU_ITEMS: 'SET_MENU_ITEMS',
  SET_CURRENT_MENU_ITEMS: 'SET_CURRENT_MENU_ITEMS',
  SET_SCROLL_STATUS: 'SET_SCROLL_STATUS',
  SET_MODAL_ON_FUNPAGE: 'SET_MODAL_ON_FUNPAGE',
  SET_SEARCHED_TEXT: 'SET_SEARCHED_TEXT',
  SET_CUSTOMER_DETAIL: 'SET_CUSTOMER_DETAIL',
  SET_CUSTOMER_POINTS: 'SET_CUSTOMER_POINTS',
  SET_COOKIE_SETTINGS: 'SET_COOKIE_SETTINGS',
  SET_VIRTUAL_VISIT_URL: 'SET_VIRTUAL_VISIT_URL',
  SET_ADDED_TO_HOME_SCREEN_STATUS: 'SET_ADDED_TO_HOME_SCREEN_STATUS',
  SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
  SET_DOWIN_CARD_REFRESH: 'SET_DOWIN_CARD_REFRESH',
  SET_MARATHON_CARD_REFRESH: 'SET_MARATHON_CARD_REFRESH',
  SET_RACKS: 'SET_RACKS',
  SET_QR_RESPONSE: 'SET_QR_RESPONSE',
  SET_LOCATION: 'SET_LOCATION',
  SET_CLOSE_CLICK: 'SET_CLOSE_CLICK',
  SET_OTP_CATEGORY_STATUSES: 'SET_OTP_CATEGORY_STATUSES',
  SET_LAST_POSITION: 'SET_LAST_POSITION',
  SET_CONTENT_TYPES: 'SET_CONTENT_TYPES',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_ACTIVE_DOWIN_COUNT: 'SET_ACTIVE_DOWIN_COUNT',
  SET_QR_VIEW_STATUS: 'SET_QR_VIEW_STATUS',
  SET_NPS_FINISHED: 'SET_NPS_FINISHED',
  SET_CHANGED_POS_TOKEN: 'SET_CHANGED_POS_TOKEN',
  SET_POPUPS: 'SET_POPUPS',
  SET_ROSETTES: 'SET_ROSETTES',
  SET_CUSTOMER_MARATHON_STATUS: 'SET_CUSTOMER_MARATHON_STATUS',
  SET_FEED_TASK_DETAIL: 'SET_FEED_TASK_DETAIL',
  CLEAR_FEED_TASK_DETAIL: 'CLEAR_FEED_TASK_DETAIL',
  SET_ROSETTE_REWARD_STATU: 'SET_ROSETTE_REWARD_STATU',
  SET_CURRENT_MARATHON: 'SET_CURRENT_MARATHON',
};

export const AppMutations = {
  [APP_MUTATION_TYPES.SET_ACTIVE_DOWIN_COUNT]: (state, version) => {
    state.activeDowinCount = version;
  },
  [APP_MUTATION_TYPES.SET_POPUPS]: (state, version) => {
    state.popups = version;
  },
  [APP_MUTATION_TYPES.SET_ROSETTES]: (state, version) => {
    state.rosettes = version;
  },
  [APP_MUTATION_TYPES.SET_CHANGED_POS_TOKEN]: (state, version) => {
    state.changedPosToken = version;
  },
  [APP_MUTATION_TYPES.SET_COOKIE_CONSENT_VERSION]: (state, version) => {
    state.cookieConsentVersion = version;
  },
  [APP_MUTATION_TYPES.SET_OVERLAY_VISIBILITY]: (state, status) => {
    state.isOverlayVisible = status;
  },
  [APP_MUTATION_TYPES.SET_OVERLAY_FUNCTION]: (state, status) => {
    state.isOverlayEnabled = status;
  },
  [APP_MUTATION_TYPES.SET_APPBAR_CONFIG]: (state, config) => {
    state.appbarConfig = config;
  },
  [APP_MUTATION_TYPES.SET_MENU_STATUS]: (state, status) => {
    state.isMenuOpened = status;
  },
  [APP_MUTATION_TYPES.SET_BOTTOMBAR_STATUS]: (state, status) => {
    state.showBottomBar = status;
  },
  [APP_MUTATION_TYPES.SET_MODAL_CONFIG]: (state, config) => {
    state.modalConfig = config;
  },
  [APP_MUTATION_TYPES.SET_MENU_ITEMS]: (state, items) => {
    state.menuItems = items;
  },
  [APP_MUTATION_TYPES.SET_CURRENT_MENU_ITEMS]: (state, currentItems) => {
    state.currentMenuItems = currentItems;
  },
  [APP_MUTATION_TYPES.SET_SCROLL_STATUS]: (state, status) => {
    state.overflow = status;
  },
  [APP_MUTATION_TYPES.SET_MODAL_ON_FUNPAGE]: (state, status) => {
    state.showModalOnFunPage = status;
  },
  [APP_MUTATION_TYPES.SET_SEARCHED_TEXT]: (state, status) => {
    state.searchedText = status;
  },
  [APP_MUTATION_TYPES.SET_CUSTOMER_DETAIL]: (state, customerDetail) => {
    state.customerDetail = customerDetail;
  },
  [APP_MUTATION_TYPES.SET_CUSTOMER_POINTS]: (state, customerPoints) => {
    state.customerPoints = customerPoints;
  },
  [APP_MUTATION_TYPES.SET_COOKIE_SETTINGS]: (state, cookieSettings) => {
    state.cookieSettings = cookieSettings;
  },
  [APP_MUTATION_TYPES.SET_VIRTUAL_VISIT_URL]: (state, url) => {
    state.virtualVisit.url = url;
  },
  [APP_MUTATION_TYPES.SET_ADDED_TO_HOME_SCREEN_STATUS]: (state, addedToHomeScreenStatus) => {
    state.addedToHomeScreenStatus = addedToHomeScreenStatus;
  },
  [APP_MUTATION_TYPES.SET_DEVICE_TYPE]: (state, type) => {
    state.deviceType = type;
  },
  [APP_MUTATION_TYPES.SET_ERROR_MODAL_CONFIG]: (state, config) => {
    state.errorModalConfig = config;
  },
  [APP_MUTATION_TYPES.SET_DOWIN_CARD_REFRESH]: (state, refresh) => {
    state.doWinCardRefresh = refresh;
  },
  [APP_MUTATION_TYPES.SET_MARATHON_CARD_REFRESH]: (state, refresh) => {
    state.marathonCardRefresh = refresh;
  },
  [APP_MUTATION_TYPES.SET_STATUS_MODAL_CONFIG]: (state, config) => {
    state.statusModalConfig = config;
  },
  [APP_MUTATION_TYPES.SET_RACKS]: (state, racks) => {
    state.racks = racks;
  },
  [APP_MUTATION_TYPES.SET_QR_RESPONSE]: (state, response) => {
    state.qrResponse = response;
  },
  [APP_MUTATION_TYPES.SET_LOCATION]: (state, location) => {
    state.location = location;
  },
  [APP_MUTATION_TYPES.SET_CLOSE_CLICK]: (state, val) => {
    state.onCloseClick = val;
  },
  [APP_MUTATION_TYPES.SET_OTP_CATEGORY_STATUSES]: (state, val) => {
    state.otpCategoryStatuses = val;
  },
  [APP_MUTATION_TYPES.SET_LAST_POSITION]: (state, val) => {
    state.lastPosition = val;
  },
  [APP_MUTATION_TYPES.SET_CONTENT_TYPES]: (state, data) => {
    state.contentTypes = data;
  },
  [APP_MUTATION_TYPES.SET_CATEGORIES]: (state, data) => {
    state.categories = data;
  },
  [APP_MUTATION_TYPES.SET_QR_VIEW_STATUS]: (state, val) => {
    state.qrViewStatus = val;
  },
  [APP_MUTATION_TYPES.SET_NPS_FINISHED]: (state, val) => {
    state.isNPSSurveyFinished = val;
  },
  [APP_MUTATION_TYPES.SET_CUSTOMER_MARATHON_STATUS]: (state, status) => {
    state.customerMarathonStatus = status;
  },
  [APP_MUTATION_TYPES.SET_FEED_TASK_DETAIL]: (state, data) => {
    state.feedTaskDetail = data;
  },
  [APP_MUTATION_TYPES.CLEAR_FEED_TASK_DETAIL]: state => {
    state.feedTaskDetail = {};
  },
  [APP_MUTATION_TYPES.SET_ROSETTE_REWARD_STATU]: (state, status) => {
    state.isRosetteRewardStatu = status;
  },
  [APP_MUTATION_TYPES.SET_CURRENT_MARATHON]: (state, data) => {
    state.currentMarathon = data;
  },
};
