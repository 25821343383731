export const FLEX_DIRECTIONS = {
  row: 'row',
  column: 'column',
  rowReverse: 'row-reverse',
  columnReverse: 'column-reverse',
};

export const FLEX_ALIGNMENTS = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
};

export const FLEX_JUSTIFIES = {
  start: 'flex-start',
  center: 'center',
  end: 'flex-end',
  around: 'space-around',
  between: 'space-between',
};

export default {
  FLEX_DIRECTIONS,
  FLEX_ALIGNMENTS,
  FLEX_JUSTIFIES,
};
