import { HttpService } from '@/services/HttpService';

class Finance {
  subdomain = '/finance';
  iyzicoSubDomain = '/financeiyzico';
  /**
   * Get invoices
   * @returns {Promise}
   */
  getInvoices(startDate, endDate) {
    return HttpService.get(`${this.subdomain}/invoices?startDate=${startDate}&endDate=${endDate}`);
  }
  /**
   * Get earchive data
   * @returns {Promise}
   */
  getArchiveData(startDate, endDate) {
    return HttpService.get(
      `${this.subdomain}/earchive/invoices?startDate=${startDate}&endDate=${endDate}`,
    );
  }
  /**
   * Get earchive single PDF Data
   * @returns {Promise}
   */
  getSinglePDFItem(invoiceNumber) {
    return HttpService.get(`${this.subdomain}/earchive/invoice/${invoiceNumber}`);
  }
  /**
   * Get earchive export data
   * @returns {Promise}
   */
  getArchiveExport(startDate, endDate) {
    return HttpService.get(
      `${this.subdomain}/earchive/invoices/export?startDate=${startDate}&endDate=${endDate}`,
    );
  }
  /**
   * Get invoice detail
   * @returns {Promise}
   */
  getInvoiceDetail(invoiceNumber) {
    return HttpService.get(`${this.subdomain}/earchive/invoice/${invoiceNumber}`);
  }
  /**
   * Get customerdetail
   * @returns {Promise}
   */
  getCustomerDetail() {
    return HttpService.get(`${this.subdomain}/customerdetail`);
  }
  /**
   * Get points log according to year
   * @returns {Promise}
   */
  getPointLogs(year) {
    if (!year) year = Number(new Date().getFullYear());
    return HttpService.get(`${this.subdomain}/pointlogcustomer?year=${year}`);
  }
  /**
   * Get point rules by partner code
   * @returns {Promise}
   */
  getPointRules(partnerCode) {
    let queryString = `?PartnerCode=${partnerCode}`;
    return HttpService.get(`${this.subdomain}/pointrules/${queryString}`);
  }
  getPointDiscountRate() {
    return HttpService.get(`${this.subdomain}/pointpmdiscountrate`);
  }

  convertPointToDiscount(partnerCode, pointValue) {
    return HttpService.put(`${this.subdomain}/pointstoamount`, { partnerCode, pointValue });
  }

  getCreditCardModels() {
    return HttpService.get(`${this.subdomain}/cardaddremoveparams`);
  }

  /**
   * Get Iyzico card page url.
   */
  getIyzicoCardPageUrl() {
    return HttpService.get(`${this.iyzicoSubDomain}/getcardpageUrl`);
  }

  /**
   * Get Iyzico EZD Bos Permission for add/remove card page
   */
  getBosPermission() {
    return HttpService.get(`${this.iyzicoSubDomain}/getbospermission`);
  }
}

export default new Finance();
