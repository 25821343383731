export const AUTH_MUTATION_TYPES = {
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_USER_MODEL: 'SET_USER_MODEL',
  SET_USER_DETAILS: 'SET_USER_DETAILS',
  SET_SELECTED_POS: 'SET_SELECTED_POS',
  SET_POS_LIST: 'SET_POS_LIST',
  SET_ACCOUNT_TYPE: 'SET_ACCOUNT_TYPE',
  SET_AFTER_LOGIN: 'SET_AFTER_LOGIN',
};

export const AuthMutations = {
  [AUTH_MUTATION_TYPES.SET_ACCESS_TOKEN]: (state, token) => {
    state.accessToken = token;
  },
  [AUTH_MUTATION_TYPES.SET_REFRESH_TOKEN]: (state, refreshToken) => {
    state.refreshToken = refreshToken;
  },
  [AUTH_MUTATION_TYPES.SET_USER_MODEL]: (state, userModel) => {
    state.user = userModel;
  },
  [AUTH_MUTATION_TYPES.SET_SELECTED_POS]: (state, selectedPos) => {
    state.selectedPos = selectedPos;
  },
  [AUTH_MUTATION_TYPES.SET_POS_LIST]: (state, posList) => {
    state.posList = posList;
  },
  [AUTH_MUTATION_TYPES.SET_USER_DETAILS]: (state, userDetails) => {
    state.userDetails = userDetails;
  },
  [AUTH_MUTATION_TYPES.SET_ACCOUNT_TYPE]: (state, accountType) => {
    state.accountType = accountType;
  },
  [AUTH_MUTATION_TYPES.SET_AFTER_LOGIN]: (state, status) => {
    state.afterLogin = status;
  },
};
