import Logger from '@/utils/logger';

const useLogger = VueInstance => {
  const isProduction = process.env.NODE_ENV === 'production';
  const loggerOptions = {
    isEnabled: true,
    logLevel: isProduction ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
  };
  VueInstance.use(Logger, loggerOptions);
};
export default useLogger;
