import { HttpService } from '@/services/HttpService';

class Campaign {
  subdomain = '/campaign';
  /**
   * Get content categories
   * @returns {Promise}
   */
  getCampaigns(filterData) {
    if (!filterData) filterData = { contentTypes: [], tags: [], searchText: '' };
    return HttpService.post(`${this.subdomain}`, filterData);
  }
  /**
   * Get content categories
   * @returns {Promise}
   */
  getCampaignFilters() {
    return HttpService.get(`${this.subdomain}/filters`);
  }
  /**
   * Get content by ID
   * @returns {Promise}
   */
  getCampaignDetail(id) {
    return HttpService.get(`${this.subdomain}/detail?Id=${id}`);
  }
}

export default new Campaign();
