import RouteBase from '@/router/routes/RouteBase.js';

class RoutesSettings {
  subFolderPath = '/settings';
  isPublic = false;

  Navigation = new RouteBase({
    name: 'settings-navigation',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'SettingsNavigation',
    isPublic: this.isPublic,
  });

  AddRemoveCreditCard = new RouteBase({
    name: 'add-remove-credit-card',
    path: 'add-remove-credit-card',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'AddRemoveCreditCard',
    isPublic: this.isPublic,
    screenCode: 11,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Kart Ekle / Çıkar',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Kart Ekle/Çıkar',
        },
      ],
    },
  });

  ChangePassword = new RouteBase({
    name: 'change-password',
    path: 'change-password',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ChangePassword',
    isPublic: this.isPublic,
    screenCode: 12,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Şifre Değiştir',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Şifre Değiştir',
        },
      ],
    },
  });

  Notifications = new RouteBase({
    name: 'notifications',
    path: 'notifications',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Notifications',
    isPublic: this.isPublic,
    screenCode: 13,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Bildirimler',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Profil',
          link: '/secure/settings/profile',
        },
        {
          name: 'Bildirimler',
        },
      ],
    },
  });

  NewNotifications = new RouteBase({
    name: 'new-notifications',
    path: 'new-notifications',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'NewNotifications',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Bildirimler',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Profil',
          link: '/secure/settings/profile',
        },
        {
          name: 'Bildirimler',
        },
      ],
    },
  });

  ContactUs = new RouteBase({
    name: 'contact-us',
    path: 'contact-us',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'ContactUs',
    isPublic: this.isPublic,
    screenCode: 14,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Bize Ulaşın',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Bize Ulaşın',
        },
      ],
    },
  });

  MyDocuments = new RouteBase({
    name: 'my-documents',
    path: 'my-documents',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'MyDocuments',
    isPublic: this.isPublic,
    screenCode: 15,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Belgelerim',
      },
    },
    breadcrumb: [
      {
        name: 'Ana Sayfa',
        link: '/',
      },
      {
        name: 'Belgelerim',
      },
    ],
  });

  PrivacyPolicy = new RouteBase({
    name: 'privacy-policy',
    path: 'privacy-policy',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'PrivacyPolicy',
    isPublic: this.isPublic,
    screenCode: 16,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Gizlilik Politikası',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Gizlilik Politikası',
        },
      ],
    },
  });

  DataPolicy = new RouteBase({
    name: 'data-policy',
    path: 'data-policy',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'DataPolicy',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Gizlilik Bildirimi',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Gizlilik Bildirimi',
        },
      ],
    },
  });

  LegalWarning = new RouteBase({
    name: 'legal-warning',
    path: 'legal-warning',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'LegalWarning',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Yasal Şartlar',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Yasal Şartlar',
        },
      ],
    },
  });

  AboutApp = new RouteBase({
    name: 'about-app',
    path: 'about-app',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'AboutApp',
    isPublic: this.isPublic,
    screenCode: 17,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Uygulama Hakkında',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Uygulama Hakkında',
        },
      ],
    },
  });

  Profile = new RouteBase({
    name: 'profile',
    path: 'profile',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Profile',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Profil Bilgileri',
        },
      ],
    },
  });

  RosettesDetail = new RouteBase({
    name: 'rosettes-detail',
    path: 'rosettes-detail',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'RosettesDetail',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Profil Bilgileri',
          link: '/secure/settings/profile',
        },
        {
          name: 'Rozetlerim',
        },
      ],
    },
  });
}

export default new RoutesSettings();
