import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesAthentication from '@/router/routes/RoutesAthentication';
import RoutesSettings from '@/router/routes/secure/RoutesSettings';
import RoutesStatic from '@/router/routes/secure/RoutesStatic';
import RoutesBusinessPartners from '@/router/routes/secure/RoutesBusinessPartners';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import RoutesUlker from '@/router/routes/secure/businessPartners/RoutesUlker';
import RoutesPeyman from '@/router/routes/secure/businessPartners/RoutesPeyman';
import RoutesUserManual from '@/router/routes/secure/RoutesUserManual';
import RoutesLearn from '@/router/routes/secure/RoutesLearn';
import RoutesMyWallets from '@/router/routes/secure/RoutesMyWallets';
import RoutesMyDocuments from './secure/RoutesMyDocuments';
import RoutesPlayAndWin from './secure/RoutesPlayAndWin';
import RoutesDoAndWin from './secure/RoutesDoAndWin';
import RoutesSurvey from './secure/RoutesSurvey';
import RoutesImageRecognition from './secure/RoutesImageRecognition';
import RoutesOtp from './secure/RoutesOtp';
import RoutesAgreements from './secure/RoutesAgreements';
import RoutesMySurveys from './secure/RoutesMySurveys';
import RoutesEntrepreneurship from './secure/RoutesEntrepreneurship';

const routes = [
  {
    ...RoutesRoot.Home.generate(),
    redirect: { name: RoutesRoot.Authentication.name },
  },
  {
    ...RoutesRoot.Authentication.generate(),
    children: [
      {
        ...RoutesAthentication.Login.generate(),
        redirect: { name: RoutesLogin.SelectLoginType.name },
        children: [
          { ...RoutesLogin.SelectLoginType.generate() },
          { ...RoutesLogin.WithPhoneNumber.generate() },
          { ...RoutesLogin.WithCustomerNumber.generate() },
          { ...RoutesLogin.WithTaxpayerIdNumber.generate() },
          { ...RoutesLogin.FirstLogin.generate() },
          { ...RoutesLogin.SmsVerification.generate() },
          { ...RoutesLogin.NewPassword.generate() },
          { ...RoutesLogin.SelectPos.generate() },
          { ...RoutesLogin.ExpiredPassword.generate() },
        ],
      },
      { ...RoutesAthentication.ForgotPassword.generate() },
      { ...RoutesAthentication.PmtepLogin.generate() },
    ],
    redirect: { name: RoutesAthentication.Login.name },
  },
  {
    ...RoutesRoot.Secure.generate(),
    children: [
      { ...RoutesSecure.Feed.generate() },
      { ...RoutesSecure.FeedDetail.generate() },
      { ...RoutesSecure.ContactGeneral.generate() },
      { ...RoutesSecure.EmergencyTrainings.generate() },
      { ...RoutesSecure.VideoSeries.generate() },
      {
        ...RoutesSecure.Surveys.generate(),
        children: [
          { ...RoutesSurvey.SurveyHome.generate() },
          { ...RoutesSurvey.TestHome.generate() },
          { ...RoutesSurvey.TakeSurvey.generate() },
        ],
      },
      { ...RoutesSecure.VirtualVisit.generate() },
      {
        ...RoutesSecure.PlayAndWin.generate(RoutesSecure.PlayAndWin.meta.screenCode),
        redirect: { name: RoutesPlayAndWin.PlayAndWinHome.name },
        children: [
          { ...RoutesPlayAndWin.PlayAndWinHome.generate(RoutesSecure.PlayAndWin.meta.screenCode) },
          { ...RoutesPlayAndWin.PlayAndWinDetail.generate() },
        ],
      },
      {
        ...RoutesSecure.DoAndWin.generate(RoutesSecure.DoAndWin.meta.screenCode),
        redirect: { name: RoutesDoAndWin.DoAndWinHome.name },
        children: [
          { ...RoutesDoAndWin.DoAndWinHome.generate(RoutesSecure.DoAndWin.meta.screenCode) },
          { ...RoutesDoAndWin.TakeQuiz.generate() },
        ],
      },
      {
        ...RoutesSecure.Settings.generate(),
        redirect: { name: RoutesSettings.Navigation.name },
        children: [
          { ...RoutesSettings.Navigation.generate() },
          { ...RoutesSettings.AddRemoveCreditCard.generate() },
          { ...RoutesSettings.ChangePassword.generate() },
          { ...RoutesSettings.Notifications.generate() },
          { ...RoutesSettings.NewNotifications.generate() },
          { ...RoutesSettings.ContactUs.generate() },
          {
            ...RoutesSettings.MyDocuments.generate(),
            redirect: { name: RoutesMyDocuments.MyDocumentsNavigation.name },
            children: [
              { ...RoutesMyDocuments.Contracts.generate() },
              { ...RoutesMyDocuments.MyDocumentsNavigation.generate() },
            ],
          },
          { ...RoutesSettings.PrivacyPolicy.generate() },
          { ...RoutesSettings.DataPolicy.generate() },
          { ...RoutesSettings.LegalWarning.generate() },
          { ...RoutesSettings.AboutApp.generate() },
          { ...RoutesSettings.Profile.generate() },
          { ...RoutesSettings.RosettesDetail.generate() },
        ],
      },
      {
        ...RoutesSecure.Statics.generate(),
        children: [
          { ...RoutesStatic.RegulationDetails.generate() },
          { ...RoutesStatic.HealthWarnings.generate() },
          { ...RoutesStatic.FrequentlyAskedQuestions.generate() },
          { ...RoutesStatic.CookiePolicy.generate() },
        ],
      },
      {
        ...RoutesSecure.BusinessPartners.generate(),
        redirect: { name: RoutesBusinessPartners.Pmi.name },
        children: [
          {
            ...RoutesBusinessPartners.Pmi.generate(),
            redirect: { name: RoutesPmi.Home.name },
            children: [
              { ...RoutesPmi.PlainPackage.generate() },
              { ...RoutesPmi.PriceList.generate() },
              { ...RoutesPmi.ProductList.generate() },
              { ...RoutesPmi.BrandDetail.generate() },
              { ...RoutesPmi.Home.generate() },
              { ...RoutesPmi.Bills.generate() },
              { ...RoutesPmi.Archive.generate() },
            ],
          },
          {
            ...RoutesBusinessPartners.Ulker.generate(),
            redirect: { name: RoutesUlker.Home.name },
            children: [
              { ...RoutesUlker.Home.generate() },
              { ...RoutesUlker.Brand.generate() },
              { ...RoutesUlker.List.generate() },
            ],
          },
          {
            ...RoutesBusinessPartners.Peyman.generate(),
            redirect: { name: RoutesPeyman.Home.name },
            children: [
              { ...RoutesPeyman.Home.generate() },
              { ...RoutesPeyman.List.generate() },
              { ...RoutesPeyman.Brand.generate() },
            ],
          },
        ],
      },
      {
        ...RoutesSecure.Learn.generate(RoutesSecure.Learn.meta.screenCode),
        redirect: { name: RoutesLearn.Home.name },
        children: [
          { ...RoutesLearn.Home.generate(RoutesSecure.Learn.meta.screenCode) },
          { ...RoutesLearn.DigitalLiteracy.generate() },
          { ...RoutesLearn.TotalLoss.generate() },
          { ...RoutesLearn.VideoDetail.generate() },
          { ...RoutesLearn.Turnover.generate() },
          { ...RoutesLearn.Endorsement.generate() },
          { ...RoutesLearn.EndorsementByCategory.generate() },
        ],
      },
      {
        ...RoutesSecure.MyWallets.generate(),
        redirect: { name: RoutesMyWallets.WalletsHome.name },
        children: [
          { ...RoutesMyWallets.ConvertScore.generate(RoutesSecure.MyWallets.meta.screenCode) },
          { ...RoutesMyWallets.WalletsDetail.generate(RoutesSecure.MyWallets.meta.screenCode) },
          { ...RoutesMyWallets.WalletsHome.generate(RoutesSecure.MyWallets.meta.screenCode) },
        ],
      },
      { ...RoutesSecure.ManageMyBusiness.generate() },
      { ...RoutesSecure.Fun.generate() },
      { ...RoutesSecure.GiftCatalogue.generate(RoutesSecure.GiftCatalogue.meta.screenCode) },
      { ...RoutesSecure.CampaignDetail.generate(RoutesSecure.Campaigns.meta.screenCode) },
      { ...RoutesSecure.Campaigns.generate(RoutesSecure.Campaigns.meta.screenCode) },
      {
        ...RoutesSecure.UserManual.generate(RoutesSecure.UserManual.meta.screenCode),
        redirect: { name: RoutesUserManual.Navigation.name },
        children: [
          { ...RoutesUserManual.Navigation.generate(RoutesSecure.UserManual.meta.screenCode) },
          { ...RoutesUserManual.Detail.generate() },
        ],
      },
      {
        ...RoutesSecure.ImageRecognition.generate(),
        redirect: { name: RoutesImageRecognition.ImageRecognition.name },
        children: [
          { ...RoutesImageRecognition.ImageRecognition.generate() },
          { ...RoutesImageRecognition.Main.generate() },
          { ...RoutesImageRecognition.QRReader.generate() },
          { ...RoutesImageRecognition.Racks.generate() },
          { ...RoutesImageRecognition.Faq.generate() },
          { ...RoutesImageRecognition.ReportProblem.generate() },
        ],
      },
      {
        ...RoutesSecure.Otp.generate(),
        redirect: { name: RoutesOtp.Otp.name },
        children: [
          { ...RoutesOtp.Otp.generate() },
          { ...RoutesOtp.Learn.generate() },
          { ...RoutesOtp.Faq.generate() },
        ],
      },
      {
        ...RoutesSecure.Entrepreneurship.generate(),
        redirect: { name: RoutesEntrepreneurship.Entrepreneurship.name },
        children: [{ ...RoutesEntrepreneurship.Entrepreneurship.generate() }],
      },
      { ...RoutesSecure.Chatbot.generate() },
      { ...RoutesSecure.Player.generate() },
      { ...RoutesSecure.Playable.generate() },
      { ...RoutesSecure.WeeklyPlayable.generate() },
      {
        ...RoutesSecure.MyAgreements.generate(),
        redirect: { name: RoutesAgreements.AgreementsHome.name },
        children: [
          {
            ...RoutesAgreements.AgreementsHome.generate(RoutesSecure.MyAgreements.meta.screenCode),
          },
          {
            ...RoutesAgreements.AgreementsDetail.generate(),
          },
        ],
      },
      { ...RoutesSecure.BeyondTime.generate() },
      { ...RoutesSecure.Certificates.generate() },
      { ...RoutesSecure.Trendyol.generate() },
      { ...RoutesSecure.TrendyolPlayer.generate() },
      {
        ...RoutesSecure.MySurveys.generate(),
        redirect: { name: RoutesMySurveys.MySurveys.name },
        children: [
          {
            ...RoutesMySurveys.MySurveys.generate(),
          },
        ],
      },
      { ...RoutesRoot.NotFound.generate() },
    ],
  },
  {
    ...RoutesRoot.NotFound.generate(),
  },
  {
    ...RoutesRoot.Ayd.generate(),
  },
  {
    ...RoutesRoot.OneTrustCookieSettings.generate(),
  },
];

export default routes;
