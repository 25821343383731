import Vue from 'vue';
import Vuex from 'vuex';
import merge from 'deepmerge';
import { DefaultState } from '@/store/state';
import FunModule from '@/store/app/modules/fun';
import { AppModule } from '@/store/app/module';
import { AuthModule } from '@/store/auth/module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: DefaultState,
});

export const registerModule = (moduleName, module) => {
  const moduleIsRegistered = store._modules.root._children[moduleName] !== undefined;
  const stateExists = store.state[moduleName] !== undefined;

  if (stateExists) {
    module.state = merge(module.state, store.state[moduleName], {
      clone: false,
      arrayMerge: (target, source) => {
        return source;
      },
    });
  }

  if (!moduleIsRegistered) {
    store.registerModule(moduleName, module, { preserveState: false });
  }
};

registerModule('app', AppModule);
registerModule('auth', AuthModule);
registerModule('fun', FunModule);
