import { HttpService, replaceOldToken } from '@/services/HttpService';
import { store } from '@/store/store';
import StorageHelper from '@/utils/storageHelper';
import { clearAppData, refreshApp } from '@/mixins/storageUtils.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import { searchIn } from '@/mixins/arrayUtils';
import { SERVICE_URLS } from '@/constants/component.constants';
import { User } from '@/services/Api/index';

const flushPendingRequests = (error, accessToken) => {
  HttpService.pendingRequests.forEach(promise => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve(accessToken);
    }
  });
  HttpService.isReAuthenticating = false;
  HttpService.pendingRequests = [];
};

const checkAppVersion = response => {
  let { AppVersion, WebVersion } = response.data.Version;
  let storageVersion = new StorageHelper({
    id: process.env.VUE_APP_STORAGE_CURRENT_VERSION,
  }).get();
  if (!storageVersion || searchIn(SERVICE_URLS.public, response.config.url)) {
    new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_VERSION }).set({
      appversion: AppVersion,
      webversion: WebVersion,
    });
  } else if (storageVersion.webversion !== WebVersion && response.config.method === 'get') {
    const errModalConfig = {
      isModalOpened: true,
      modalText:
        'PMAktif’i en güncel versiyonuyla kullanmaya devam etmek için lütfen “TAMAM” a tıklayınız.',
      firstButtonText: 'TAMAM',
      noTitle: true,
      noClose: true,
      alertType: 'warn',
      firstButtonFunction: () => {
        store.dispatch('app/setErrorModalConfig', {});
        refreshApp(AppVersion, WebVersion);
      },
    };
    store.dispatch('app/setErrorModalConfig', errModalConfig);
  }
};

const resetAppToLogin = () => {
  clearAppData(
    'logout',
    `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectLoginType.path}`,
  );
};

const isExpireError = error => {
  return (
    error &&
    error.response &&
    error.response.status === 401 &&
    error.response.data &&
    error.response.data.Error &&
    error.response.data.Error.Code === 990
  );
};

const setupResponseInterceptor = router => {
  HttpService.interceptors.response.use(
    response => {
      store.dispatch('app/setOverlayVisibility', false);
      if (
        response &&
        response.data &&
        response.data.Version &&
        response.data.Version.AppVersion &&
        response.data.Version.WebVersion
      ) {
        checkAppVersion(response);
      }
      return response;
    },
    error => {
      const originalRequest = error.config;
      store.dispatch('app/setOverlayVisibility', false);
      if (isExpireError(error)) {
        if (HttpService.isReAuthenticating) {
          return new Promise((resolve, reject) => {
            HttpService.pendingRequests.push({ resolve, reject });
          })
            .then(accessToken => {
              return HttpService(replaceOldToken(originalRequest, accessToken));
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }

        HttpService.isReAuthenticating = true;

        return new Promise((resolve, reject) => {
          try {
            HttpService.post('/account/refresh', {
              accessToken: store.state.auth.accessToken,
              refreshToken: store.state.auth.refreshToken,
              selectedPosId: store.state.auth.selectedPos.id,
            })
              .then(res => {
                if (
                  res.data &&
                  res.data.Data &&
                  res.data.Data.token &&
                  res.data.Data.refreshToken
                ) {
                  store
                    .dispatch(
                      'auth/setAccessToken',
                      res.data.Data.token,
                      res.data.Data.refreshToken,
                    )
                    .then(() => {
                      new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(
                        res.data.Data.token,
                      );
                      new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                        res.data.Data.refreshToken,
                      );
                      flushPendingRequests(null, res.data.Data.token);
                      resolve(HttpService(replaceOldToken(originalRequest, res.data.Data.token)));
                      User.getUserDetail().then(res => {
                        if (!res || !res.data.Data || !res.data.Data.userDetail) {
                          return;
                        }
                        if (res && res.data.Data && res.data.Data.userDetail) {
                          let userDetails = res.data.Data.userDetail;
                          new StorageHelper({
                            id: process.env.VUE_APP_STORAGE_CURRENT_USER_DETAIL,
                          }).set(userDetails);
                          store.dispatch('auth/setUserDetails', userDetails);
                          if (window.insider_object?.user)
                            window.insider_object.user.custom = userDetails;
                        }
                      });
                    });
                } else {
                  resetAppToLogin(router);
                }
              })
              .catch(err => {
                resetAppToLogin(router);
                reject(err);
              });
          } catch (e) {
            e.status = 403;

            flushPendingRequests(e);
            resetAppToLogin(router);
            reject(e);
          }
        });
      } else {
        if (
          error.response &&
          error.response.data &&
          error.response.data.Error &&
          error.response.data.Error.Display === 1
        ) {
          const errModalConfig = {
            isModalOpened: true,
            modalText: error.response.data.Error.Message,
          };
          store.dispatch('app/setErrorModalConfig', errModalConfig);
        }
      }
      return Promise.reject(error);
    },
  );
};
export default setupResponseInterceptor;
