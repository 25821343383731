import RouteBase from '@/router/routes/RouteBase.js';

class RoutesAgreements {
  subFolderPath = '/myAgreements';
  isPublic = false;

  AgreementsHome = new RouteBase({
    name: 'agreements',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Agreements',
    isPublic: this.isPublic,
    meta: {
      icon: 'iconAgreement',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Puan Kriterlerim',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Puan Kriterlerim',
          link: '/secure/agreements',
        },
      ],
    },
  });

  AgreementsDetail = new RouteBase({
    name: 'agreement-detail',
    path: 'agreement/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'AgreementsDetail',
    isPublic: this.isPublic,
    meta: {
      hideBottomBar: false,
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: 'Puan Kriterlerim Detay',
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Puan Kriterlerim',
          link: '/secure/agreements',
        },
        {
          name: 'Puan Kriterlerim Detay',
        },
      ],
    },
  });
}

export default new RoutesAgreements();
