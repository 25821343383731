import Vue from 'vue';

import { createApp } from '@/appInit';

import { initHttpService } from '@/services/HttpService';

import StorageHelper from '@/utils/storageHelper';

const { app, router, store } = createApp();

initHttpService(store, router);

import './registerServiceWorker';

import checkView from 'vue-check-view';
Vue.use(checkView);

const delay = 300000;
const baseApiUrl = process.env.VUE_APP_API_BASE_URL;
const versionType = process.env.VUE_APP_VERSION_TYPE;
const versionApiCaller = () => {
  let xhr = new XMLHttpRequest();
  const fileVersion = localStorage.getItem('pmaFileVersion');
  xhr.open('GET', `${baseApiUrl}/versioncheck?version=${fileVersion}&type=${versionType}`);

  xhr.send();

  xhr.onload = function() {
    if (xhr.status !== 200 && xhr.readyState === 4) {
      const location = window.location;
      localStorage.setItem('pmaFileVersion', xhr.response.replaceAll('"', ''));
      window.location = location;
    }
    setTimeout(versionApiCaller, delay);
  };
};

setTimeout(versionApiCaller, 1000);

/**
 * global error handler that redirects to error page and logs error to express app
 */
Vue.config.errorHandler = error => {
  console.error(error); // tslint:disable-line

  // HttpService.post('/log/error', {
  //   error: {
  //     message: error.message,
  //     stack: error.stack,
  //   },
  // });
  const islogoutFlagRaised = new StorageHelper({
    id: process.env.VUE_APP_STORAGE_USER_LOGOUT_FLAG,
  }).get();
  !islogoutFlagRaised && router.replace('/error');
};

router.onReady(() => {
  router.beforeResolve(async (to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);
    let diffed = false;

    const activated = matched.filter((component, index) => {
      return diffed || (diffed = prevMatched[index] !== component);
    });

    if (!activated.length) {
      return next();
    }

    try {
      await Promise.all(
        activated.map(component => {
          if (component.prefetch) {
            return component.prefetch({
              store,
              route: to,
              router,
            });
          }

          return Promise.resolve();
        }),
      );

      next();
    } catch (e) {
      console.error(e);
      next();
    }
  });

  // Browser version control

  const Bowser = require('bowser');
  const browser = Bowser.getParser(window.navigator.userAgent);
  var isMobile = function() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent,
    );
  };

  var supportedVersions = {
      web: {
        chrome: 57,
        safari: 10.1,
        microsoftedge: 16,
        firefox: 52,
        opera: 44,
      },
      mobile: {
        chrome: 57,
        safari: 10.3,
        samsunginternetforandroid: 6.2,
      },
    },
    currentBrowser = browser.getBrowser();

  var platform = isMobile() ? 'mobile' : 'web';

  if (
    Object.keys(supportedVersions[platform])
      .map(function(item) {
        return item.toLowerCase();
      })
      .indexOf(currentBrowser.name.replace(/\s+/g, '').toLowerCase()) > -1
  ) {
    var supportedVersion =
      supportedVersions[platform][currentBrowser.name.replace(/\s+/g, '').toLowerCase()];
    // Mobile ise os al değilse bir şey yapma
    // if (isMobile()) {
    //   supportedVersion = isAndroid() ? supportedVersion[1] : supportedVersion[0];
    // }
    if (currentBrowser.name.toLowerCase() === 'safari') {
      if (parseFloat(supportedVersion) > parseFloat(browser.parsedResult.os.version)) {
        document.getElementById('version-modal-wrapper').style.display = 'block';
      }
    } else {
      if (parseFloat(supportedVersion) > parseFloat(currentBrowser.version)) {
        document.getElementById('version-modal-wrapper').style.display = 'block';
      }
    }
  } else {
    document.getElementById('version-modal-wrapper').style.display = 'block';
  }
  // Browser Version Control End

  app.$mount('#app');
});
