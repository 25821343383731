import { HttpService } from '@/services/HttpService';

class ImageRecognition {
  subdomain = '/imagerecognition';
  /**
   * Upload image
   * @param {FormData} {Required} image file
   * @returns {Promise}
   */
  uploadImage(url, data) {
    return HttpService.put(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'No Auth',
      },
    });
  }
  /**
   * Qr read
   * @param {string} {Required} Encripted qr code
   * @returns {Promise}
   */
  qrRead(data, config) {
    return HttpService.post(`${this.subdomain}/qrread`, data, config);
  }
  /**
   * Check missing skues
   * @param {Array} {Required} images
   * @param {string} {Required} location
   * @param {string} {Required} customerCode
   * @param {number} {Required} rackCount
   * @returns {Promise}
   */
  checkMissingSkues(data) {
    return HttpService.post(`${this.subdomain}/missingskus`, data);
  }
  /**
   * Send process
   * @param {Array} {Required} images
   * @param {string} {Required} location
   * @param {string} {Required} customerCode
   * @param {number} {Required} rackCount
   * @returns {Promise}
   */
  process(data) {
    return HttpService.post(`${this.subdomain}/process`, data);
  }

  /**
   * get problem types
   * @returns {Promise}
   */
  getProblemTypes() {
    return HttpService.get(`${this.subdomain}/problemtypes`);
  }

  /**
   * Report An Issue
   * @param {number} {Required} reportingTypeId
   * @returns {Promise}
   */
  reportProblem(data) {
    return HttpService.post(`${this.subdomain}/reportproblem`, data);
  }

  /**
   * Error Log on qr read and image upload
   * @param {string} posCode customer Code on qr
   * @param {string} detail
   * @returns {Promise}
   */
  errorLog(data) {
    return HttpService.post(`${this.subdomain}/errorLog`, data);
  }
}

export default new ImageRecognition();
