import RouteBase from '@/router/routes/RouteBase.js';

class RoutesPeyman {
  subFolderPath = '/businessPartners/peyman';
  isPublic = false;

  Home = new RouteBase({
    name: 'peyman-home',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Home',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        icon: 'wave',
        title: 'Peyman',
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Peyman',
        },
      ],
    },
  });

  Brand = new RouteBase({
    name: 'brand',
    path: ':brand/:id',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'Brand',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Peyman',
          link: '/secure/business-partners/peyman',
        },
        {
          name: 'Kategori',
        },
      ],
    },
  });

  List = new RouteBase({
    name: 'list',
    path: 'price-list',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'List',
    isPublic: this.isPublic,
    meta: {
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Peyman',
          link: '/secure/business-partners/peyman',
        },
        {
          name: 'Güncel Fiyat Listesi',
        },
      ],
    },
  });
}

export default new RoutesPeyman();
