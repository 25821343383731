export default {
  code: 'tr',
  messages: {
    alpha: '{_field_} yalnızca harf içerebilir',
    alpha_dash: '{_field_} alanı harf ve tire (-) ya da alttan tire (_) içerebilir',
    alpha_num: '{_field_} yalnızca harf ve rakam içerebilir',
    alpha_spaces: '{_field_} yalnızca harf boşluk (space) içerebilir',
    between: '{_field_} {min} ile {max} aralığında olmalıdır',
    confirmed: '{_field_} doğrulaması hatalı',
    digits: '{_field_} sayısal ve {length} basamaklı olmalıdır',
    dimensions: '{_field_} alanı {width} piksel ile {height} piksel arasında olmalıdır',
    email: '{_field_} alanının geçerli bir e-posta olması gerekir',
    excluded: '{_field_} alanına geçerli bir değer giriniz',
    ext: '{_field_} alanı geçerli bir dosya olmalıdır',
    image: '{_field_} alanı resim dosyası olmalıdır',
    oneOf: '{_field_} alanına geçerli bir değer giriniz',
    max: '{_field_} alanı {length} karakterden fazla olmamalıdır',
    max_value: '{_field_} alanı {max} ya da daha az bir değer olmalıdır',
    mimes: '{_field_} geçerli bir dosya olmalıdır',
    min: '{_field_} alanına en az {length} karakter girilmelidir',
    min_value: '{_field_} alanı {min} ya da daha fazla bir değer olmalıdır',
    numeric: '{_field_} alanına sayısal bir değer giriniz',
    regex: '{_field_} formatı geçersiz',
    required: '{_field_} alanı gereklidir',
    required_if: '{_field_} alanı gereklidir',
    size: "{_field_} alanı {size}KB'dan daha az olmalıdır",
    multiples: '{_field_} alanı {0} veya katları olmalıdır',
    decimal: '{_field_} alanına sayısal bir değer giriniz',
  },
};
