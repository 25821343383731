const state = () => ({
  activeTabId: null,
  showMoreCount: 12,
  showMoreStatus: false,
});

const getters = {
  activeTab: state => {
    return state.activeTabId;
  },

  showMoreCount: state => {
    return state.showMoreCount;
  },

  showMoreStatus: state => {
    return state.showMoreStatus;
  },
};

const actions = {
  selectedTab({ commit }, id) {
    commit('setClickedTab', { id });
  },

  showMoreCount({ commit }, count) {
    commit('setShowMoreCount', { count });
  },
};

const mutations = {
  setClickedTab(state, { id }) {
    state.activeTabId = id;
  },

  setShowMoreCount(state, { count }) {
    state.showMoreCount = count;
  },

  setShowMoreStatus(state, { status }) {
    state.showMoreStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
