<template>
  <component
    :key="renderKey"
    :is="as"
    :class="cssClasses.modalWrapperClasses"
    v-if="mergedConfig.isModalOpened || false"
    @click="closeToModal"
  >
    <a v-if="!mergedConfig.noClose" @click="closeModal" :class="cssClasses.modalCloseClasses">
      <VueIcon
        iconName="IconCross"
        iconColor="#FFF"
        :width="getIcon.cross.width"
        :height="getIcon.cross.height"
      ></VueIcon>
    </a>
    <div :class="cssClasses.modalContentClasses">
      <div :class="cssClasses.modalContentWrapperClasses">
        <div>
          <div :class="cssClasses.modalIconWrapper">
            <VueIcon
              :key="mergedConfig.alertType"
              :iconName="getAlertName"
              :width="getIcon.warn.width"
              :height="getIcon.warn.height"
              color="#E5472D"
            />
          </div>
          <div :class="$style.modalTitle">
            <VueText sizeLevel="12" weightLevel="5" v-if="!mergedConfig.noTitle">Üzgünüz! </VueText>
          </div>
          <div>
            <VueText
              as="span"
              sizeLevel="7"
              v-if="mergedConfig.modalText"
              v-html="mergedConfig.modalText"
            >
            </VueText>
          </div>
        </div>
      </div>
      <div :class="cssClasses.modalButtonWrapperClasses">
        <BrandButton @click="clickFirstButton" :size="sizes.xxLarge">{{
          mergedConfig.firstButtonText
        }}</BrandButton>
      </div>
    </div>
  </component>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { mapGetters } from 'vuex';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';

export default {
  name: 'BrandErrorModal',
  components: {
    VueIcon,
    BrandButton,
    VueText,
  },
  props: {
    as: {
      type: String,
      default: 'div',
    },
  },
  data() {
    return {
      renderKey: 0,
      modalConfig: {
        modalType: 'error',
        contentAlignment: FLEX_JUSTIFIES.center,
        textAlignment: FLEX_JUSTIFIES.center,
        alertType: 'error',
        firstButtonText: 'KAPAT',
      },
    };
  },
  watch: {
    mergedConfig: {
      handler: function(newVal) {
        this.renderKey += 1;
        document.body.style.overflow = newVal.isModalOpened ? 'hidden' : 'unset';
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    mergedConfig() {
      return { ...this.modalConfig, ...this.getErrorModalConfig };
    },
    cssClasses() {
      const modalWrapperClasses = [this.$style[`modal-wrapper`]];
      const modalContentClasses = [this.$style[`modal-content`]];
      const modalContentWrapperClasses = [
        this.$style[`modal-content-wrapper`],
        this.$style[`modal-content-${this.mergedConfig.contentAlignment}`],
      ];
      const modalIconWrapper = [this.$style[`modal-content-icon`]];
      const modalCloseClasses = [this.$style[`modal-close`]];
      const modalButtonWrapperClasses = [this.$style[`modal-button-wrapper`]];

      if (this.mergedConfig.textAlignment) {
        modalContentWrapperClasses.push(
          this.$style[`modal-content-text-${this.mergedConfig.textAlignment}`],
        );
      }

      return {
        modalWrapperClasses,
        modalCloseClasses,
        modalContentClasses,
        modalIconWrapper,
        modalButtonWrapperClasses,
        modalContentWrapperClasses,
      };
    },
    getIcon() {
      const { apply, cross, warn } = ICON_VARIABLES;
      return { cross, warn, apply };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
    ...mapGetters('app', ['getErrorModalConfig']),
    getAlertName() {
      switch (this.mergedConfig.alertType) {
        case 'error':
          return 'IconError';
        case 'warn':
          return 'IconWarn';
        default:
          return 'IconApply';
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('app/setErrorModalConfig', {});
    },
    closeToModal(e) {
      if (e.target.classList.contains(this.cssClasses.modalWrapperClasses)) {
        this.$store.dispatch('app/setErrorModalConfig', {});
      }
    },
    clickFirstButton() {
      if (this.mergedConfig.firstButtonFunction) {
        this.mergedConfig.firstButtonFunction();
      } else {
        this.closeModal();
      }
    },
  },
};
</script>
<style module lang="scss">
.modal {
  &-wrapper {
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: palette-space-level('20');
    z-index: 40;
    top: 0;
    left: 0;
    object-fit: contain;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.7);
  }

  &-content {
    max-width: 500px;
    overflow: hidden; // Alt radius gorunmemesi durumu icin
    margin: auto;
    z-index: 1;
    background-color: palette-color-level('white', '100');
    border-radius: 4px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    word-break: break-word;

    &-text {
      &-center {
        text-align: center;
      }
    }
    &-wrapper {
      display: flex;
      justify-content: center;
      min-width: 400px;
      padding: palette-space-level('45');
    }
    @each $variation, $alignment in $palette-flex-alignments {
      &-#{$alignment} {
        align-items: $alignment;
      }
    }
    &-icon {
      margin-bottom: palette-space-level('30');
      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  &-button-wrapper {
    display: flex;
    padding: 2px;
  }
}
.modalTitle {
  margin-bottom: palette-space-level('10');
}
</style>
