import RouteBase from '@/router/routes/RouteBase.js';

class RoutesUserManual {
  subFolderPath = '/userManual';
  isPublic = false;
  title = 'Kullanım Kılavuzu';

  Navigation = new RouteBase({
    name: 'user-manual-navigation',
    path: '',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'UserManualNavigation',
    isPublic: this.isPublic,
    meta: {
      icon: 'info',
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: `${this.title}`,
      },
      breadcrumb: [
        {
          name: 'Ana Sayfa',
          link: '/secure/feed',
        },
        {
          name: 'Kullanım Kılavuzu',
          link: '/secure/user-manual',
        },
        {
          name: 'Video',
        },
      ],
    },
  });

  Detail = new RouteBase({
    name: 'user-manual-detail',
    path: 'detail/:id?',
    componentFolderPath: `${this.subFolderPath}`,
    componentFileName: 'UserManualDetail',
    isPublic: this.isPublic,
    meta: {
      appbarConfig: {
        isCollapsable: false,
        hasBackButton: true,
        title: `${this.title}`,
      },
    },
  });
}

export default new RoutesUserManual();
