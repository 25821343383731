import { HttpService } from '@/services/HttpService';

class BusinessDashboard {
  subdomain = '/performance';

  getPreOrderHistory(queryString) {
    return HttpService.get(`businessdashboard/preOrderHistory?${queryString}`);
  }

  getSaleTracking(options) {
    const { productFamilyCode, productSubFamilyCode, startDate, endDate } = options;

    return HttpService.get(
      `${this.subdomain}/saletracking?productFamilyCode=${productFamilyCode}&productSubFamilyCode=${productSubFamilyCode}&startDate=${startDate}&endDate=${endDate}`,
    );
  }

  getPointTracking() {
    return HttpService.get(`${this.subdomain}/pointtracking`);
  }

  getIncomeTracking() {
    return HttpService.get(`${this.subdomain}/incometracking`);
  }

  getProductTracking() {
    return HttpService.get(`${this.subdomain}/producttracking`);
  }
}

export default new BusinessDashboard();
